import React from 'react'
import { toast } from 'react-toastify'

import Toast from './Toast'

const parseType = type =>
	type === 'ok' ? 'success' : type === 'err' ? 'error' : type === 'warn' ? 'warning' : type

const useToaster = (settings = {}) => {
	return (msg, { type, ...options } = {}) =>
		type
			? toast[parseType(type)](<Toast type={type}>{msg}</Toast>, {
					...settings,
					...options,
			  })
			: toast(<Toast type={type}>{msg}</Toast>, {
					...settings,
					...options,
			  })
}

export default useToaster
