export default {
	fonts: {
		textFamily: `'PT Sans Caption', sans-serif`,
		titleFamily: `'Oswald', sans-serif`,
	},

	colors: {
		primary: '#bb7900',
		secondary: '#138de4',
		aux1: '#eaad00',

		grey: '#758B9A',
		black: '#0F0F0E',
		err: '#dc3300',
		ok: '#5bbb12',
		warn: '#ec9800',
		loader: '#138de4',
	},

	times: {
		tranS: '200ms ease-in-out',
		tranM: '400ms ease-in-out',
		tranL: '600ms ease-in-out',
	},
}
