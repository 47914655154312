import { FieldArray, Field, useFormikContext } from 'formik'
import get from 'lodash/get'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Btn } from '../..'
import useState from '../../../../utils/useState'

const SingleArrayFieldView = styled.div`
	label {
		color: ${props => props.theme.colors.grey.val};
		display: block;
		padding-bottom: 1em;
	}
	input {
		border: none;
		border-bottom: solid 1px ${props => props.theme.colors.grey.val};
		padding: 0.5em 1em;
		box-shadow: none;
		outline: none;
		display: block;
	}
	.single-array-field {
		margin-bottom: 0.5em;
		&:last-child {
			margin: none;
		}
	}
`

const ArrayField = ({ name, idx, helpers, values, setFocusIdx, focusIdx }) => {
	const { remove, push } = helpers
	const keyRef = useRef()
	// console.log('field values: ', values)
	useEffect(() => {
		if (keyRef.current && idx) keyRef.current.focus()
		if (idx && idx !== focusIdx) setFocusIdx(idx)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (focusIdx === idx && keyRef.current) keyRef.current.focus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focusIdx])
	return (
		<div className='single-array-field'>
			<Field
				name={name}
				innerRef={e => (keyRef.current = e)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						if (typeof e.preventDefault === 'function') e.preventDefault()
						push('')
					} else if (e.keyCode === 8) {
						if (!values?.length && idx) {
							setFocusIdx(idx - 1)
							remove(idx)
						}
					}
				}}
			/>
		</div>
	)
}

const SingleArrayField = ({ name, label }) => {
	const [focusIdx, setFocusIdx] = useState(null, 'focusIdx')
	const { values } = useFormikContext()
	const arrayVals = get(values, name)
	return (
		<SingleArrayFieldView>
			{label ? <label>{label}</label> : null}
			<FieldArray
				name={name}
				render={helpers => {
					// console.log('arrayVals: ', arrayVals)
					// console.log('values: ', values)
					return (
						<div className='single-array-fields'>
							<>
								{arrayVals?.length ? (
									arrayVals.map((_, idx) => (
										<ArrayField
											key={idx}
											name={`${name}.${idx}`}
											helpers={helpers}
											idx={idx}
											values={arrayVals[idx]}
											focusIdx={focusIdx}
											setFocusIdx={setFocusIdx}
										/>
									))
								) : (
									<ArrayField
										name={`${name}.${0}`}
										helpers={helpers}
										idx={0}
										values={arrayVals[0]}
									/>
								)}
								<Btn onClick={() => helpers.push('')}>Add</Btn>
							</>
						</div>
					)
				}}
			/>
		</SingleArrayFieldView>
	)
}

export default SingleArrayField
