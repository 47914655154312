import React from 'react'
import { Redirect } from '@reach/router'

import { Form, Fields, Board } from '../../../components/UI'

import { useCreatePass } from '../../../auth'

import { useStoreState } from '../../../store'

const { SubmitBtn, Pass } = Fields

const CreatePass = props => {
	const { isAuthed, dashPath } = useStoreState(state => state.user)

	const createPW = useCreatePass()

	const submitHandler = async ({ password }) => {
		try {
			await createPW(password)
		} catch (err) {
			return Promise.reject(err)
		}
	}
	return isAuthed ? (
		<Redirect to={dashPath || '/mcp/dash'} noThrow />
	) : (
		<Board titleText='Create password'>
			<Form
				initialValues={{
					password: '',
				}}
				onSubmit={async (vals, { setSubmitting }) => {
					try {
						await submitHandler(vals)
					} catch (err) {}
				}}
			>
				{({ isValid, isSubmitting }) => (
					<>
						<Pass name='password' placeholder='Create new password' isReq />
						<SubmitBtn isValid={isValid} isSubmitting={isSubmitting} isInverted>
							Create Password
						</SubmitBtn>
					</>
				)}
			</Form>
		</Board>
	)
}
CreatePass.propTypes = {}
export default CreatePass
