import React from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import * as Yup from 'yup'
import defaultsDeep from 'lodash/defaults'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { Fields, Form } from '../../../../components/UI'

import LinkSectionFields from './MCP.Event.section.Links'
import PicFields from './MCP.Event.section.Pics'
import ScheduleFields from './MCP.Event.section.Schedule'

import config from '../../../../styles/theme.config'
import EventSectionProfiles from './MCP.Event.section.Profiles'

const { Text, SubmitBtn, Checkbox, RT, IconSelect } = Fields

const sectionDefaultVals = {
	type: '',
	hide: false,
	hideOnStage: false,
	name: '',
	icon: '',
}
const MCPEventSection = ({
	submitHandler,
	label = 'Event Section',
	values = {},
	defaultVals = {},
	schema: schma = {},
	eventCode,
	formID,
}) => {
	const initialVals = defaultsDeep(values, defaultVals, sectionDefaultVals)

	const schema = Yup.object({
		name: Yup.string().required('Please enter a section title'),
		icon: Yup.string().required('Please choose an icon'),
		...schma,
	})

	return (
		<Form
			initialValues={initialVals}
			validationSchema={schema}
			onSubmit={vals => {
				submitHandler(vals)
			}}
			className='scrollable'
		>
			{() => (
				<>
					<header>
						{label ? <legend>{label}</legend> : null}
						<SubmitBtn>{isEmpty(values) ? 'Add Section' : 'Save Section'}</SubmitBtn>
					</header>
					<div className='scroll'>
						<div className='scroll-child'>
							<Checkbox
								name='hide'
								label='Hide on live site? (will still show up on staging site)'
							/>
							<Checkbox
								name='hideOnStage'
								label='Hide on staging site? (will still show up on live site)'
							/>
							<Text name='name' label='Section title' isReq />
							<IconSelect
								name='icon'
								label='Icon for menu'
								allow={['user', 'utility', 'misc']}
								isReq
							/>
							<>
								{formID === 'links' ? (
									<LinkSectionFields />
								) : formID === 'pics' ? (
									<PicFields folder={eventCode} />
								) : formID === 'schedule' ? (
									<ScheduleFields />
								) : formID === 'profiles' ? (
									<EventSectionProfiles />
								) : (
									<RT name='content' label='Content' colors={map(config.colors, val => val)} />
								)}
							</>
						</div>
					</div>
				</>
			)}
		</Form>
	)
}
MCPEventSection.propTypes = {
	submitHandler: PropTypes.func.isRequired,
}
export default MCPEventSection
