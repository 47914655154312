import React, { useEffect } from 'react'
import styled from 'styled-components'
import toUpper from 'lodash/toUpper'
import useAsync from '../../../../utils/useAsync'
import { events as apiEvents } from '../../../../api/api.mcp'
import Block from '../../../UI/Block'
import { Spinner } from '../../../UI/Loaders'
import { H5, P } from '../../../UI'
import { useFormikContext } from 'formik'
import { Btn } from '../../../UI/Typography'
import { FieldArray } from '../../../UI/Forms/FormFields'
import Icon from '../../../UI/Icons'

const LandingSectionEventListFieldsView = styled.div`
	.event {
		display: block;
		padding: 0.5em 0;
		color: ${props => props.theme.colors.grey.dark().val};
		&.__selected {
			.icon {
				opacity: 1;
			}
		}
		.icon {
			color: ${props => props.theme.colors.ok.val};
			opacity: 0;
		}
	}
`

const LandingSectionEventListFields = () => {
	const { values } = useFormikContext()

	const { events } = values || { events: [] }

	const { res, isWorking, err, cancel } = useAsync(() =>
		apiEvents.get({ orgID: '5ecafd5a1480065f3fbab3ac' })
	)

	useEffect(() => {
		return () => {
			cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return err ? (
		<Block>Error</Block>
	) : isWorking ? (
		<Spinner />
	) : (
		<LandingSectionEventListFieldsView>
			<H5>Events List</H5>
			<FieldArray
				name='events'
				render={arrayHelpers => (
					<div className='list'>
						{res?.length ? (
							res.map(({ name, eventCode, id, hide }, idx) => {
								const isSelected = !!events.find(e => e.id === id)
								return (
									<Btn
										className={`event${isSelected ? ' __selected' : ''}`}
										key={id}
										onClick={() => {
											if (isSelected) {
												arrayHelpers.remove(idx)
											} else {
												arrayHelpers.push({
													id,
												})
											}
										}}
									>
										<Icon type='ok' />
										{`${hide ? '[HIDDEN] ' : ''}${name} || ${toUpper(eventCode)}`}
									</Btn>
								)
							})
						) : (
							<P>No Events found... Please try again later.</P>
						)}
					</div>
				)}
			/>
		</LandingSectionEventListFieldsView>
	)
}
LandingSectionEventListFields.propTypes = {}
export default LandingSectionEventListFields
