import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const Footer = styled.footer`
	.copyright {
		padding: 0.5em;
		font-size: 0.8em;
		text-align: center;
		color: ${props => props.theme.colors.grey.val};
		background: ${props => props.theme.colors.black.val};
	}
`

const MainFooter = props => {
	return (
		<Footer {...props}>
			<div className='copyright'>
				copyright © {new Date().getFullYear()} The Production Alliance 501(c)3
			</div>
		</Footer>
	)
}
MainFooter.propTypes = {}
export default MainFooter
