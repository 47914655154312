import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from '../Icons'

const Label = styled.label`
	color: ${props => props.theme.colors.secondary.val};
	background: ${props => props.theme.colors.white.val};
	padding: 1em;
	display: flex;
	align-items: center;
	&:hover {
		color: ${props => props.theme.colors.white.val};
		background: ${props => props.theme.colors.secondary.val};
		cursor: pointer;
	}
	${props => props.theme.media.tablet} {
		margin-left: auto;
	}
	input {
		display: none;
	}
	.icon {
		height: 0.9em;
		margin-left: 0.5em;
	}
`

const UploadBtn = ({ browseHandler, ...props }) => {
	return (
		<Label htmlFor='new-file-for-upload' {...props}>
			<input
				id='new-file-for-upload'
				type='file'
				onChange={e => browseHandler(e.target.files[0])}
			/>
			Browse
			<Icon type='arrowRight' />
		</Label>
	)
}
UploadBtn.propTypes = {
	browseHandler: PropTypes.func.isRequired,
}
export default UploadBtn
