import React from 'react'
import PropTypes from 'prop-types'

import { Fields, H5, EM, P } from '../../../components/UI'
import { mcp } from '../../../api'

const { Text, Textarea, Color, UploadField, Select, Checkbox, ArrayFields, IconSelect } = Fields

const BasicInfo = ({ folderName }) => {
	return (
		<div className='flex-desk desk-wrap'>
			<div className='col'>
				<H5 className='banner'>Basic Info</H5>
				<Checkbox name='hide' label='Hide event?' />
				<Text name='name' label='Event name' isReq />
				<Text name='eventCode' label='Event code' isReq />
				<Text type='date' name='date' label='Start Date' isReq />
				<Text type='date' name='endDate' label='End Date' />
				<Text name='slug' label='Slug (URL path)' isReq />
				<Textarea name='tagline' label='Tagline' isReq rows='2' />
				<Textarea name='metaDescription' label='SEO Description' />

				<H5 className='banner'>Look and Feel</H5>
				<Color name='color' label='Theme Color' placement='bottomRight' />
				<UploadField
					name='bgImg'
					label='Upload/Select background image'
					isReq='Please upload/select an image'
					folder={folderName}
					allowedFolders={[folderName]}
					api={mcp.files}
				/>
				<Select
					name='bgImgFormat'
					label='Background image justification'
					options={['top', 'center', 'bottom', 'left', 'right']}
				/>
				<H5 className='banner'>Event Branding</H5>
				<UploadField
					name='branding.bannerImg'
					label='Banner Image (optimal size: 1000px x 200px)'
					folder={folderName}
					allowedFolders={[folderName]}
					api={mcp.files}
				/>
				<UploadField
					name='branding.posterImg'
					label='Poster Graphic (optimal size: 500px x 773px)'
					folder={folderName}
					allowedFolders={[folderName]}
					api={mcp.files}
				/>
			</div>
			<div className='col'>
				<H5 className='banner'>Notification Banner</H5>
				<P>
					<EM>Currently this is the orange covid info that appears on the event page.</EM>
				</P>
				<Checkbox name='notificationBanner.hide' label='Hide Banner?' />
				<Checkbox
					name='notificationBanner.useThemeColor'
					label='Use "Theme Color" (otherwise will be orange)'
				/>
				<IconSelect
					name='notificationBanner.icon'
					label='Banner Icon'
					allow='alerts'
					showGroups={false}
				/>
				<Text
					name='notificationBanner.text'
					label='Banner text'
					helperText='Will use global banner text if left empty'
				/>

				<H5 className='banner'>CTA (Call To Action) Button</H5>
				<Checkbox
					name='cta.hide'
					label='Hide CTA? (will also be hidden if no CTA Link is provided)'
				/>
				<Text name='cta.text' label='CTA Text' />
				<Text name='cta.subText' label='CTA Sub-text' />
				<Text
					name='cta.to'
					label='CTA Link'
					helperText='External links must start with "http", internal must start with "/"'
				/>

				<H5 className='banner'>Volunteers/Vendors</H5>
				<Checkbox name='volunteers.isSignupAllowed' label='Allow Volunteer Signups?' />
				<Checkbox name='vendors.isSignupAllowed' label='Allow Vendor Signups?' />

				<H5 className='banner'>Social Media</H5>
				<ArrayFields name='socs' addText='Add Social'>
					<Checkbox name='hide' label='Hide?' />
					<IconSelect name='icon' label='Icon' isPreview previewType='icon' isReq allow='socs' />
					<Text name='to' label='URL' isPreview />
				</ArrayFields>
			</div>
		</div>
	)
}
BasicInfo.propTypes = {
	folderName: PropTypes.string.isRequired,
}
export default BasicInfo
