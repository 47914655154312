import fetch from 'axios'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import get from 'lodash/get'

import Auth from '@aws-amplify/auth'

const MAIN_API_URI = process.env.REACT_APP_API_URL
const PRINT_TRACE = false
const ALLOW_MOCKS = true
const DISABLE_API = false

console.log('INITIALIZING API AT: ', MAIN_API_URI)
export const api = async (method, path, options = {}) => {
	let res = {}
	const { params, body, apiUri, transData, noCreds, noPrint, mock, file, onProgress } = options
	const apiPath = apiUri || MAIN_API_URI

	let data = body

	if (!noPrint)
		console[PRINT_TRACE ? 'error' : 'log'](
			`%cAPI ${method} to [ ${path} ] beginning with options: `,
			`color:#FFF; background-color:#758B9A; padding: 4px;`,
			options
		)

	try {
		let token
		if (!noCreds) {
			const session = await Auth.currentSession()
			token = session.getIdToken().getJwtToken()
		}

		if (file) {
			data = new FormData()

			data.append('file', file)
		}

		if (params)
			each(params, (val, param) => {
				if (isArray(val))
					params[param] = val.reduce((str, a) => (isEmpty(str) ? a : `${str},${a}`), '')
			})

		const url = `${apiPath}${path}`

		const req = {
			method,
			url,
			// withCredentials: !noCreds,
			// credentials: !noCreds && 'include',
			params,
			data,
			headers: {
				'Authorization': !noCreds && `Bearer ${token}`,
				// 'accesstoken': !noCreds && token,
				'Accept': 'application/json',
				'Content-Type': file ? null : 'application/json',
			},
		}

		if (onProgress) req.onUploadProgress = onProgress

		if (!noPrint)
			console[PRINT_TRACE ? 'error' : 'log'](
				`%cAPI ${method} to [ ${path} ] req: `,
				`color:#FFF; background-color:#138de4; padding: 4px;`,
				req
			)

		if (DISABLE_API) return {}

		res =
			mock && !isFunction(mock) && ALLOW_MOCKS
				? {
						data: {
							data: mock,
						},
				  }
				: await fetch(req)

		if (!noPrint) console.log('API', method, ' | ', url, ' |  res:', res)
		res = get(res, 'data.data', res)

		if (isFunction(transData)) res = transData(res)

		if (isFunction(mock) && ALLOW_MOCKS) res = mock(res)

		if (!noPrint)
			console[PRINT_TRACE ? 'error' : 'log'](
				`%cAPI ${method} to [ ${path} ] returned with results: `,
				`color:#FFF; background-color:#5bbb12; padding: 4px;`,
				res
			)

		return res
	} catch (err) {
		console.dir(err)

		if (!noPrint)
			if (get(err, 'response.data.error'))
				console.error(
					`%cAPI ${method} to [ ${path} ] error response: `,
					`color:#FFF; background-color:#dc3300; padding: 4px;`,
					get(err, 'response.data.error')
				)
			else
				console.error(
					'API error (without message):',
					new Error(get(err, 'response.data.error.msg', 'no error message returned from api'))
				)

		return Promise.reject(get(err, 'response.data.error', { msg: 'System error' }))
	}
}

/**
 *  CURRENT USER
 * */

export const me = {
	get: options => api('get', '/me', options),
	update: (body, options = {}) =>
		api('put', '/me', {
			body,
			...options,
		}),
}
