import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import defaultsDeep from 'lodash/defaultsDeep'
import isString from 'lodash/isString'
import split from 'lodash/split'
import * as Yup from 'yup'

import { useState, parseQueryParams } from '../../../utils'
import { mcp } from '../../../api'
import { useStoreState } from '../../../store'

import {
	Board,
	Block,
	Fields,
	Form,
	LoaderHOC,
	Link,
	Icon,
	P,
	useToaster,
	Modal,
	Tabs,
} from '../../../components/UI'

import BasicInfo from './MCP.Event.basicInfo'
import Sections from './MCP.Event.Sections.js'
import Profiles from './MCP.Event.Profiles.js/index.js'

import Wrap from './MCP.Event.view'

const schema = Yup.object({
	name: Yup.string().required('Please enter a name for the event'),
	eventCode: Yup.string().required('Please enter a unique code (e.g., "cake-2020")'),
	date: Yup.string().required('Please select a start date for the event'),
	slug: Yup.string().required('Please enter a unique slug for url').matches(/^\S+$/, {
		message: 'Slug cannot contain spaces (HINT: use a dash instead)',
		excludeEmptyString: true,
	}),
	tagline: Yup.string().required('Please enter a tagline'),
	cta: Yup.object({
		text: Yup.string().max(25, 'Max 25 characters'),
		subText: Yup.string().max(50, 'Max 50 characters'),
		to: Yup.string(),
		hide: Yup.bool(),
	}),
	profilesNavIcon: Yup.string().required('Please select an icon'),
})

const defaultEventFields = {
	hide: false,
	name: '',
	eventCode: '',
	date: '',
	endDate: '',
	dateOverride: '',
	tagline: '',
	color: '',
	slug: '',
	cta: {
		hide: false,
		text: '',
		subText: '',
		to: '',
	},
	metaDescription: '',
	bgImg: {},
	bgImgFormat: 'right',
	branding: {
		bannerImg: {},
		posterImg: {},
	},
	notificationBanner: {
		icon: 'medMask',
		useThemeColor: false,
		text: '',
		hide: false,
	},
	volunteers: {
		isSignupAllowed: true,
	},
	vendors: {
		isSignupAllowed: true,
	},
	socs: [],
	sections: [],
	profilesTitle: '',
	profilesNavIcon: 'farm',
	profilesSort: 'drag',
	hideProfiles: false,
	profiles: [],
}

const { SubmitBtn } = Fields

const getInitialVals = event => {
	if (!event) return defaultEventFields

	const vals = defaultsDeep(
		{
			...event,
		},
		defaultEventFields
	)
	return vals
}

const MCPEvent = ({ location }) => {
	const { id } = parseQueryParams(location.search)
	const [event, setEvent] = useState(null, 'event')
	const [error, setError] = useState(null, 'error')
	const { tpa_id } = useStoreState(state => state.user.me)
	const [publishConfirmTxt, setPublishConfirmTxt] = useState(null, 'publishConfirmTxt')
	// const [isPublishing, setPublishing] = useState(false, 'isPublishing')
	// const [hasSaved, setHasSaved] = useState(false, 'hasSaved')
	const isWorking = useRef(false)

	const toast = useToaster()

	useEffect(() => {
		const loadEvent = async () => {
			try {
				const loadedEvent = await mcp.events.get({ eventID: id })

				setEvent(loadedEvent)
			} catch (err) {
				setError(err.msg)
			}
		}
		if (!isWorking.current && !event && id) loadEvent()
	}, [id, event])

	const submitHandler = async vals => {
		try {
			if (id) await mcp.events.update(vals)
			else await mcp.events.create(tpa_id, vals)
			toast(`${vals.name} successfully updated`, { type: 'ok' })
			// setHasSaved(true)
		} catch (err) {
			toast(`error saving event: ${err.msg}`, { type: 'err' })
			return Promise.reject(err)
		}
	}

	// const publish = async (e, server) => {
	// 	try {
	// 		e.preventDefault()
	// 		setPublishing(true)
	// 		const res = await mcp.events.publish(server)
	// 		toast(`Publish Initiated`, { type: 'ok' })
	// 		setPublishConfirmTxt(
	// 			res.env === 'staging' ? res.env : res.env === 'dev' ? 'no build triggered (in dev)' : true
	// 		)
	// 		setPublishing(false)
	// 		setHasSaved(false)
	// 	} catch (err) {
	// 		// do nothing for now
	// 		setPublishing(false)
	// 	}
	// }

	const folderName = event && split(event.eventCode, '-')[0]

	return (
		<Wrap>
			<Board
				isFull
				titleText={event ? event.name : 'Create New Event'}
				subTitleText={
					<Link to='/mcp/events'>
						<Icon type='arrowLeft' />
						Back to Events
					</Link>
				}
			>
				<LoaderHOC isDone={!error && (id ? !!event : true)}>
					<Form
						initialValues={getInitialVals(event)}
						onSubmit={async (vals, { setSubmitting, isValid }) => {
							try {
								await submitHandler(vals)
							} catch (err) {
								setSubmitting(false)
								isValid = true
							}
						}}
						validationSchema={schema}
					>
						{({ isValid, isSubmitting, dirty }) => (
							<>
								{error && (
									<Block type='err' usage='overlay' close={() => setError(null)}>
										{error}
									</Block>
								)}
								<div className='flex-tablet'>
									<Tabs>
										<BasicInfo nav='Basic Info' folderName={folderName} />
										<Sections nav='Sections' folderName={folderName} />
										<Profiles nav='Profiles' folderName={folderName} />
									</Tabs>
									<div className='submit-wrap flex'>
										<SubmitBtn isInverted isValid={isValid} isSubmitting={isSubmitting}>
											{id ? 'Save & Publish' : 'Create & Publish'} Event
										</SubmitBtn>
										{/* <div className='publish-btns'>
											<Btn
												onClick={publish}
												disabled={!hasSaved || !dirty || !isValid || isPublishing}
											>
												Publish Changes
											</Btn>
											<Btn
												onClick={e => publish(e, 'staging')}
												disabled={!hasSaved || !dirty || !isValid || isPublishing}
											>
												Publish (staging only)
											</Btn>
										</div> */}
									</div>
								</div>
							</>
						)}
					</Form>
				</LoaderHOC>
			</Board>
			<Modal
				isOpen={!!publishConfirmTxt}
				closeHandler={() => setPublishConfirmTxt(null)}
				titleText='Publication initiated'
			>
				<P>Publication of new content requires rebuilding and deploying the site.</P>
				<P>
					{`Rebuilding/deployment takes up to 1-2 min, after which the new content should be available for viewing.`}
				</P>
				{isString(publishConfirmTxt) ? (
					<P>{`Site currently rebuilding (where content will viewable): ${publishConfirmTxt}`}</P>
				) : null}
			</Modal>
		</Wrap>
	)
}
MCPEvent.propTypes = {
	event: PropTypes.shape({
		name: PropTypes.string,
	}),
}
export default MCPEvent
