import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '../../images/TPA-logo'
import { Link } from '../UI'

const Branding = styled(Link)`
	fill: ${props => props.theme.colors.white.val};
	width: 30px;
`

const HeaderBranding = props => {
	return (
		<Branding {...props} to='/'>
			<Logo />
		</Branding>
	)
}
HeaderBranding.propTypes = {}
export default HeaderBranding
