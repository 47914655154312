import { useRef, useEffect } from 'react'
import some from 'lodash/some'
import includes from 'lodash/includes'
import { useAuth } from '../auth'
import { useStoreState } from '../store'
import { useState } from './utils'

const useProtect = ({ allowed }) => {
	const authenticate = useAuth()
	const { isAuthed, groups, isAdmin } = useStoreState(state => state.user)

	const [hasAuthed, setHasAuthed] = useState(isAuthed, 'hasAuthed')

	const isProcessing = useRef(false)

	const isAllowed = allowed ? some(groups, group => includes(allowed, group)) : true

	const hasPermissions = isAuthed && (isAdmin || isAllowed)

	useEffect(() => {
		const auth = async () => {
			isProcessing.current = true
			try {
				await authenticate()
				// console.log('useProtect - auth success!')
				isProcessing.current = false
				setHasAuthed(true)
			} catch (err) {
				console.error('useProtect -  auth error: ', err)
				isProcessing.current = false
				setHasAuthed(true)
			}
		}
		if (!hasAuthed && !isProcessing.current) auth()
	}, [hasAuthed, authenticate])

	return [hasPermissions, hasAuthed]
}
export default useProtect
