import React from 'react'
import DisclaimerFields from '../../Disclaimer.fields'

const LandingSectionDisclaimerFields = () => {
	return (
		<>
			<DisclaimerFields />
		</>
	)
}
export default LandingSectionDisclaimerFields
