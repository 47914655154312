import styled from 'styled-components'

export default styled.div`
	.upload-wrap {
		width: 100%;
		margin-bottom: 1em;
		& > .flex {
			justify-content: center;
			width: 100%;
		}
		.browse-btn {
			${props => props.theme.media.tablet} {
				margin-left: auto;
			}
		}
		.upload-hr {
			margin: 0;
		}
	}
	.action-bar {
		flex-direction: row-reverse;
		${props => props.theme.media.tablet} {
			padding-bottom: 2em;
		}
	}

	main.library-section {
		width: 100%;
		${props => props.theme.media.tablet} {
			padding-bottom: 3em;
			width: auto;
			flex-grow: 1;
			align-items: flex-start;
		}
		.folders {
		}
		.library {
			flex-grow: 1;
		}
	}

	aside.file-options {
		margin-left: auto;
		display: none;
		${props => props.theme.media.tablet} {
			display: block;
			overflow-x: hidden;
		}
		${props => props.theme.media.sdesk} {
			min-width: 300px;
		}
	}
`
