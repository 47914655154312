import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loader from './Table.loader.row'
import Cell from './Table.cell'

const Row = styled.tr`
	${props => props.theme.media.sdesk} {
		&:hover {
			background: ${props => (props.isLoading ? 'none' : props.theme.colors.grey.tint().val)};
		}
	}
`

const TableRow = ({ id, cols, colsQty, ...props }) => {
	return (
		<Row {...props} isLoading={!cols}>
			{cols ? (
				cols.map((col, i) =>
					get(col, 'txt') ? (
						<Cell key={`${id}-${i}`} {...col}>
							{col.txt}
						</Cell>
					) : (
						<Cell key={`${id}-${i}`}>{col}</Cell>
					)
				)
			) : (
				<Loader qty={colsQty} Cell={Cell} />
			)}
		</Row>
	)
}
TableRow.propTypes = {
	cols: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
	colsQty: PropTypes.number.isRequired,
}
export default TableRow
