import React from 'react'
// import map from 'lodash/map'

import { Fields } from '../../../UI'
// import config from '../../../../styles/theme.config'

const { Select } = Fields

const LandingSectionFormFields = () => {
	return (
		<>
			{/* <RT name='content' label='Content (Above Form)' colors={map(config.colors, val => val)} /> */}
			<Select
				name='version'
				label='Form'
				defaultTxt='Select a form...'
				options={[
					{ txt: 'General Contact', val: 'gen' },
					{ txt: 'Sponsor Application', val: 'sponsor' },
					{ txt: 'Vendor Application', val: 'vendor' },
					{ txt: 'Volunteer Application', val: 'volunteer' },
				]}
			/>
		</>
	)
}

export default LandingSectionFormFields
