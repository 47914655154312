import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Fields, H5 } from '../../../../components/UI'
import Form from './MCP.Event.profiles.form'

const { ArrayFields, Select } = Fields

const Wrap = styled.div``

const sortOptions = [
	{
		txt: `Drag & Drop (how they're sorted here)`,
		val: 'drag',
	},
	{
		txt: 'A-Z',
		val: 'a-z',
	},
	{
		txt: 'Z-A',
		val: 'z-a',
	},
]

const Profiles = ({ folderName }) => {
	return (
		<Wrap>
			<H5>Event Profiles (sub-pages)</H5>
			<Select name='profilesSort' label='Sort profiles (on website) by...' options={sortOptions} />
			<ArrayFields
				name='profiles'
				addText='Add Profile'
				form={{
					previewFields: ['name'],
					form: p => <Form folderName={folderName} {...p} />,
				}}
			/>
		</Wrap>
	)
}
export default Profiles
