/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@swfsoft/swf-theme'
import { useState } from '../../utils'

import BarContainer from './Bar.container'

const ProgressBar = ({ isDone, startBar, height, className }) => {
	const [showBar, setShowBar] = useState(startBar, 'showBar')
	const { times } = useTheme()

	useEffect(() => {
		if (startBar && !showBar) setShowBar(true)
	}, [startBar])

	useEffect(() => {
		if (isDone)
			setTimeout(() => {
				setShowBar(false)
			}, times.long || 600)
	}, [isDone])

	return showBar && <BarContainer isDone={isDone} height={height} className={className} />
}

ProgressBar.propTypes = {
	isDone: PropTypes.bool,
	startBar: PropTypes.bool,
}
export default React.memo(ProgressBar)
