import React from 'react'

import { P } from '../../../../components/UI'

const EventSectionProfiles = () => {
	return (
		<>
			<P>This is just for configuring the profiles section on the event page.</P>
			<P>To edit the actual profiles please use the "Profiles" tab.</P>
		</>
	)
}
export default EventSectionProfiles
