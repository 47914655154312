import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useField } from 'formik'
import ReactQuill from 'react-quill'

import styles from 'react-quill/dist/quill.snow.css'

import Label from '../Label'

const Wrap = styled.div`
	${styles}

	padding-bottom: 2em;
	.quill {
		.ql-editor {
			min-height: 200px;
			${props => props.theme.media.sdesk} {
				min-height: 300px;
				width: 700px;
			}
			h1,
			h2,
			h3 {
				padding: 1em 0;
			}
			p {
				padding: 0.5em 0;
			}
		}
		.ql-tooltip {
			transform: translateX(50%);
		}
	}
`

const RTField = ({ className, label, colors, ...props }) => {
	// eslint-disable-next-line
	const [field, meta, helpers] = useField(props)

	return (
		<Wrap className={className}>
			{label && <Label>{label}</Label>}
			<ReactQuill
				theme='snow'
				value={field.value}
				onChange={val => helpers.setValue(val)}
				modules={{
					toolbar: [
						[{ header: [false, 2, 3, 4, 5, 6] }],
						['bold', 'italic', 'underline', 'strike'],
						[{ color: colors }, { background: colors }],
						[{ align: [] }, { indent: '-1' }, { indent: '+1' }],
						['blockquote', 'code-block', 'link'],
						// [{ script: 'sub' }, { script: 'super' }],
						[{ list: 'ordered' }, { list: 'bullet' }],
						['clean'],
						['video'],
						// ['image'],
					],
				}}
			/>
		</Wrap>
	)
}
RTField.propTypes = {
	className: PropTypes.string,
}
export default RTField
