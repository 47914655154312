import React from 'react'

import { Board, FileUploader } from '../../../components/UI'
import { parseQueryParams } from '../../../utils'
import { mcp } from '../../../api'

const MCPMediaLibrary = ({ location }) => {
	const { dir = 'misc' } = parseQueryParams(location.search)
	return (
		<Board isFull titleText='File Manager'>
			<FileUploader
				folder={dir}
				fetchFolders={mcp.files.getFolders}
				uploadHandler={mcp.files.upload}
				updateFileHandler={mcp.files.update}
			/>
		</Board>
	)
}
MCPMediaLibrary.propTypes = {}
export default MCPMediaLibrary
