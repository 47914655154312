import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

import { Btn } from '../Typography'

const Ul = styled.ul`
	display: flex;
	align-items: center;
	border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};
	li {
		border-bottom: 1px solid transparent;
		border-top: solid 1px ${props => props.theme.colors.grey.light().val};

		&:hover {
			background: ${props => props.theme.colors.grey.light('6').val};
		}
		&.active {
			border-bottom: 1px solid ${props => props.theme.colors.secondary.val};
		}
	}
	button {
		font-size: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 3em;
		padding: 0 2em;
		text-transform: uppercase;
	}
`

const TabNav = ({ tabs, currentIdx, selectHandler }) => (
	<Ul>
		{tabs.map((tab, idx) => (
			<li className={currentIdx === idx ? 'active' : ''} key={idx}>
				<Btn
					isBlank
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						selectHandler(idx)
					}}
				>
					{get(tab, 'props.nav', `Tab ${idx + 1}`)}
				</Btn>
			</li>
		))}
	</Ul>
)

TabNav.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
		})
	),
	currentIdx: PropTypes.number.isRequired,
	selectHandler: PropTypes.func.isRequired,
}
export default TabNav
