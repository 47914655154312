import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Skeleton } from '../Loaders'

const Loader = styled.div`
	min-width: 70px;
	min-height: 1.5em;
`

const TableRowLoader = ({ qty = 1, Cell }) => {
	return Array(qty)
		.fill()
		.map((_, i) => (
			<Cell key={`key-${i}`}>
				<Loader>
					<Skeleton />
				</Loader>
			</Cell>
		))
}
TableRowLoader.propTypes = {
	qty: PropTypes.number.isRequired,
	Cell: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
}
export default TableRowLoader
