import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import Cleave from 'cleave.js/react'
// eslint-disable-next-line no-unused-vars, import/extensions
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us.js'
import get from 'lodash/get'
import concat from 'lodash/concat'
import { FieldArray as FA, useField } from 'formik'
import FieldWrap from './FieldWrap'
import SubmitButton from './SubmitButton'
import Sel from './Select'
import ColorPicker from './ColorPicker'

import stateList from '../statelist'

const CATCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY || '6LfhbhoUAAAAACmUKhSLJ5l4sXe6G289MGvqpUJm'

const Input = memo(props => <input {...props} />)
const FieldLogic = props => {
	const {
		name,
		value,
		onChange,
		onFocus,
		onKeyDown,
		type,
		ref,
		onBlur,
		placeholder,
		children: render,
		id,
		readOnly,
		inputId,
		...rest
	} = props
	const [field, meta] = useField({ name, value, type })
	// console.log('FieldContainer beginning with: ', props)
	// console.log('FieldContainer field: ', field, ' meta: ', meta)

	const isFormik = !!get(field, 'onBlur')

	const inputProps = {
		...field,
		ref,
		onBlur: onBlur ? e => onBlur(e, { field, meta }) : field.onBlur,
		onChange: onChange ? e => onChange(e, { field, meta }) : field.onChange,
		onKeyDown: onKeyDown ? e => onKeyDown(e, { field, meta }) : field.onKeyDown,
		value: isFormik ? field.value : value,
		onFocus,
		readOnly,
		placeholder,
		type,
		id: id || inputId,
	}
	inputProps['data-testid'] = name
	return (
		<FieldWrap {...meta} {...rest} type={type} inputName={name} inputId={id || inputId}>
			{render(inputProps)}
		</FieldWrap>
	)
}
FieldLogic.propTypes = {
	name: PropTypes.string.isRequired,
	onFocus: PropTypes.func,
	renderWrap: PropTypes.bool,
	onChange: PropTypes.func,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	onBlur: PropTypes.func,
	children: PropTypes.func.isRequired,
	id: PropTypes.string,
	inputId: PropTypes.string,
}
const FieldContainer = memo(FieldLogic)

export const Text = props => {
	return (
		<FieldContainer {...props}>{p => <Input {...p} type={props.type || 'text'} />}</FieldContainer>
	)
}

export const Number = props => {
	return (
		<FieldContainer {...props}>
			{p => <Input {...p} type={props.type || 'number'} />}
		</FieldContainer>
	)
}

export const Textarea = ({ rows = 5, cols = 50, ...props }) => (
	<FieldContainer type='textarea' {...props}>
		{p => <textarea rows={rows} cols={cols} {...p} />}
	</FieldContainer>
)

export const Email = props => (
	<FieldContainer icon='email' placeholder='tpa@example.com' {...props}>
		{p => <Input {...p} type='text' />}
	</FieldContainer>
)

export const Pass = props => (
	<FieldContainer icon='lockAlt' placeholder='password...' {...props}>
		{p => <Input {...p} type='password' />}
	</FieldContainer>
)

export const Phone = props => (
	<FieldContainer icon='phone' placeholder='phone number...' {...props}>
		{p => (
			<Cleave
				{...p}
				options={{
					delimiters: ['(', ') ', '-'],
					blocks: [0, 3, 3, 4],
					delimiterLazyShow: true,
				}}
			/>
		)}
	</FieldContainer>
)

export const Select = ({ options, defaultTxt, ...props }) => {
	return (
		<FieldContainer type='select' {...props}>
			{p => (
				<Sel
					{...p}
					options={defaultTxt ? concat([{ val: '', txt: defaultTxt }], options) : options}
				/>
			)}
		</FieldContainer>
	)
}

export const Checkbox = props => (
	<FieldContainer
		type='checkbox'
		labelPosition='right'
		inputId={`checkbox-id-${props.name}`}
		{...props}
	>
		{p => <Input {...p} id={`checkbox-id-${props.name}`} />}
	</FieldContainer>
)

export const Radio = ({ options, ...props }) =>
	options.map(({ val, id, label }, idx) => {
		const inputId = id || `${props.name}-${idx}`
		return (
			<FieldContainer {...props} value={val} label={label} inputId={inputId} key={inputId}>
				{p => <Input {...p} type='radio' />}
			</FieldContainer>
		)
	})
Radio.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			id: PropTypes.string,
			label: PropTypes.string,
		})
	),
}

export const FieldArray = ({ name, render, ...props }) => (
	<FA name={name} render={render} {...props} />
)
FieldArray.propTypes = {
	name: PropTypes.string.isRequired,
	render: PropTypes.func.isRequired,
}

export const StateSelect = ({ useFullNames, displayFullNames, ...props }) => (
	<Select
		name='state'
		label='State'
		options={stateList.map(state => {
			const option = {
				val: useFullNames ? state.full : state.abrev,
				txt: displayFullNames || useFullNames ? state.full : state.abrev,
			}
			return option
		})}
		{...props}
	/>
)
StateSelect.propTypes = {
	useFullNames: PropTypes.bool,
	displayFullNames: PropTypes.bool,
}

export const MultiInput = memo(({ isHorizontal, children, ...props }) => {
	const [isActive, setIsActive] = useState(false)
	return (
		<FieldWrap isActive={isActive} isMulti multiHoriz={isHorizontal} {...props}>
			{children.map(C => {
				const Component = React.cloneElement(C, {
					key: C.props.name,
					renderWrap: false,
					onFocus: () => setIsActive(true),
					onBlur: (e, { field }) => {
						setIsActive(false)
						field.onBlur(e)
					},
				})
				return Component
			})}
		</FieldWrap>
	)
})
MultiInput.propTypes = {
	isHorizontal: PropTypes.bool,
	children: PropTypes.arrayOf(
		PropTypes.shape({
			props: PropTypes.shape({}).isRequired,
		})
	).isRequired,
}

export const Recaptcha = ({ name, setFieldValue }) => (
	<ReCAPTCHA sitekey={CATCHA_KEY} onChange={response => setFieldValue(name, response)} />
)
Recaptcha.propTypes = {
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
}

export const SubmitBtn = ({ isSubmitting, isValid, type, ...props }) => (
	<SubmitButton type={type || 'submit'} isSubmitting={isSubmitting} isValid={isValid} {...props} />
)
SubmitBtn.propTypes = {
	isSubmitting: PropTypes.bool,
	isValid: PropTypes.bool,
	type: PropTypes.string,
}

export const Address = ({ isReq, ...props }) => (
	<div className='address-field'>
		<MultiInput label='Street' isReq={isReq}>
			<Text name='address.street' />
			<Text name='address.street2' />
		</MultiInput>
		<div className='flex city-state'>
			<Text name='address.city' label='City' isReq={isReq} />
			<StateSelect name='address.state' className='state-select-wrap' />
		</div>
		<div className='flex country-zip'>
			<Text name='address.zip' label='Zip/Postal Code' />
			<Text name='address.country' label='Country' />
		</div>
	</div>
)

export const Color = props => {
	return (
		<FieldContainer {...props} labelPosition='right'>
			{p => <ColorPicker {...p} />}
		</FieldContainer>
	)
}

export { default as ArrayFields } from './ArrayFields'

export { default as SingleArrayField } from './SingleArrayField'

export { default as RT } from './RTField'

export { default as UploadField } from './UploadField'

export { default as IconSelect } from './FAIconSelect'
