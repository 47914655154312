import React from 'react'
import styled from 'styled-components'
import defaultsDeep from 'lodash/defaultsDeep'
import Form from '../UI/Forms/Form'
import SponsorFormFields, { SponsorSchema } from './Sponsor.fields'
import { SubmitBtn } from '../UI/Forms/FormFields'

const SponsorFormView = styled.div`
	.form-header {
		display: flex;
		.submit-btn {
			margin-left: auto;
		}
	}
`

const defaultVals = {
	orgName: '',
	link: '',
	logo: null,
}

const SponsorForm = ({ data, handler }) => {
	const initialVals = defaultsDeep(data, defaultVals)
	return (
		<SponsorFormView>
			<Form initialValues={initialVals} onSubmit={handler} validationSchema={SponsorSchema}>
				{() => (
					<>
						<header className='form-header'>
							<SubmitBtn>Save</SubmitBtn>
						</header>
						<SponsorFormFields />
					</>
				)}
			</Form>
		</SponsorFormView>
	)
}
export default SponsorForm
