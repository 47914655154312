import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import defaultsDeep from 'lodash/defaultsDeep'
import * as Yup from 'yup'

import Form from '../../UI/Forms/Form'
import Tabs from '../../UI/Tabs'
import LandingInfoForm from './Landing.info'
import LandingSectionsForm from './Landing.sections'
import LandingSEOForm from './Landing.seo'

import { Fields, Link } from '../../UI'

const { SubmitBtn } = Fields

const LandingPageFormView = styled.div`
	h5 {
		margin-bottom: 1em;
	}
	form {
		& > header {
			padding: 1em 0;
			display: flex;
			.submit-btn {
				margin-left: auto;
			}
		}
	}
`

const defaultPageData = {
	type: 'landing',
	schemaID: 'page',
	slug: '',
	name: '',
	description: '',
	seo: {
		type: 'WebPage',
		title: '',
		description: '',
		keywords: [''],
		keywordString: '',
	},
	sections: [
		{
			icon: 'hero',
			version: 'one',
			type: 'hero',
			hide: false,
			hideOnStage: false,
			name: 'Hero',
			sectionTitle: '',
			hideSectionTitle: false,
			formID: 'hero',
			featuredItems: [],
		},
	],
}

const schema = Yup.object().shape({
	slug: Yup.string().required('Pages must have a url safe slug'),
	name: Yup.string().required('Pages must include a name for internal use'),
	sections: Yup.array().required(`Pages must have at least a 'Hero' section`),
	seo: Yup.object().shape({
		title: Yup.string().required('Please enter a meta title for the page'),
		description: Yup.string().required('Please add a meta description for the page'),
	}),
})

const LandingPageForm = ({ data = {}, handler }) => {
	return (
		<LandingPageFormView>
			<Link to='/mcp/pages'>Back to Pages</Link>
			<Form
				initialValues={defaultsDeep(data, defaultPageData)}
				onSubmit={handler}
				validationSchema={schema}
			>
				{() => (
					<>
						<header>
							<SubmitBtn>Save & Publish</SubmitBtn>
						</header>
						<Tabs>
							<LandingInfoForm nav='Info' isNewPage={!data?.slug} />
							<LandingSectionsForm nav='Sections' />
							<LandingSEOForm nav='SEO' />
						</Tabs>
					</>
				)}
			</Form>
		</LandingPageFormView>
	)
}
LandingPageForm.propTypes = {
	handler: PropTypes.func.isRequired,
}
export default LandingPageForm
