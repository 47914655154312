import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useAsync, useState } from '../utils'

import uniqBy from 'lodash/uniqBy'
import includes from 'lodash/includes'

import { Btn, H6 } from '../Typography'
import { Spinner } from '../Loaders'
import Icon from '../Icons'
import Block from '../Block'

const Folders = styled.div`
	padding-right: 1em;
	padding-bottom: 2em;
	${props => props.theme.media.tablet} {
		padding-bottom: 0;
	}
	${H6} {
		margin-bottom: 2em;
	}
	menu {
		li {
			list-style: none;
			display: inline-flex;
			align-items: center;
			width: 50%;
			&::before {
				opacity: 0;
				content: '';
				height: 2em;
				min-width: 2px;
				background: ${props => props.theme.colors.secondary.val};
			}
			&.selected {
				&::before {
					opacity: 1;
				}
			}
			${props => props.theme.media.tablet} {
				display: flex;
			}
			button {
				padding: 0.5em 1em;
				white-space: nowrap;
			}
		}
	}
`

const FileUploaderFolders = ({ handleSelect, selectedFolder, allowedFolders, fetchFolders }) => {
	const { res, err } = useAsync(fetchFolders)

	const [folders, setFolders] = useState(null, 'folders')

	useEffect(() => {
		const setupFolders = () => {
			let foldersArry = uniqBy([...res, { name: 'misc' }], 'name')

			if (allowedFolders)
				foldersArry = foldersArry.filter(({ name }) => includes(allowedFolders, name))

			setFolders(foldersArry)
		}

		if (!folders && res) setupFolders()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [res])

	return (
		<Folders className='folders'>
			<H6>Folders</H6>
			{err && <Block type='err'>{err.msg}</Block>}
			{folders ? (
				<menu>
					{folders.map(({ name }) => (
						<li key={name} className={selectedFolder === name ? 'selected' : ''}>
							<Btn
								className='folder-btn'
								onClick={e => {
									e.preventDefault()
									handleSelect(name)
								}}
							>
								<Icon type='folder' />
								{name}
							</Btn>
						</li>
					))}
				</menu>
			) : (
				<Spinner />
			)}
		</Folders>
	)
}
FileUploaderFolders.propTypes = {
	handleSelect: PropTypes.func.isRequired,
	fetchFolders: PropTypes.func.isRequired,
}
export default FileUploaderFolders
