/*
 =================================================
  Error handling - all caught exceptions should be sent through here
 =================================================
* */
import isString from 'lodash/isString'
import isError from 'lodash/isError'
import get from 'lodash/get'
import { useTheme } from '@swfsoft/swf-theme'

import { useStoreState } from '../store'

// for direct usage
export const errHandler = ({
	err,
	code = 'none',
	print,
	alert,
	doNotTrack,
	context,
	errTrackClient,
	colors,
	...errTrackingOptions
}) => {
	try {
		if (isString(err)) err = new Error(err)

		const trackingOpts = errTrackingOptions || {}

		// send to tracking service
		if (!doNotTrack && errTrackClient) {
			const error = isError(err) ? err : new Error(get(err, 'msg', 'General error'))
			errTrackClient.notify(error, {
				beforeSend: report => {
					report.metaData = {
						...report.metaData,
						context,
						...trackingOpts,
					}
				},
			})
		}
		// display alert
		// TODO: implement toaster?

		// print
		if (print) {
			console.error(
				`Error with code: ${code} occured at: ${print}`,
				`color:${get(colors, 'white.val', '#FFF')}; background-color:${get(
					colors,
					'err.val',
					'#dc330'
				)}; padding: 4px;`,
				err
			)
		}
	} catch (err) {
		console.error('Error handler has thrown an error: ', err)
	}
}

// react hook version
export const useErrHandler = (options = {}) => {
	const errTrackClient = useStoreState(state => state.errTrackClient)
	const { colors } = useTheme() || {}

	return (opts = {}) => {
		errHandler({
			errTrackClient,
			colors,
			...options,
			...opts,
		})
	}
}

export { default as Error } from './Error'
