import React from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import { Link as ReachLink } from '@reach/router'
import { isExternalLink } from '../utils'

export const P = styled.p`
	${props => (props.theme.css.p ? props.theme.css.p : '')}
`

export const H1 = styled.h1`
	${props => (props.theme.css.h1 ? props.theme.css.h1 : '')}
`
export const H2 = styled.h2`
	${props => (props.theme.css.h2 ? props.theme.css.h2 : '')}
`
export const H3 = styled.h3`
	${props => (props.theme.css.h3 ? props.theme.css.h3 : '')}
`

export const H4 = styled.h4`
	${props => (props.theme.css.h4 ? props.theme.css.h4 : '')}
`
export const H5 = styled.h5`
	${props => (props.theme.css.h5 ? props.theme.css.h5 : '')}
	${props => (props.mbot ? 'margin-bottom:1em;' : '')}
	&.banner {
		color: ${props => props.theme.colors.white.val};
		padding: 0.3em 1em;
		background: ${props => props.theme.colors.grey.val};
		${props => props.theme.media.tablet} {
			padding: 0.5em 1em;
		}
	}
`
export const H6 = styled.h6`
	${props => (props.theme.css.h6 ? props.theme.css.h6 : '')}
`

export const HR = styled.hr`
	background-color: ${props => props.theme.colors.grey.light().val};
	border: solid 1px ${props => props.theme.colors.grey.light().val};
	color: ${props => props.theme.colors.grey.light().val};
	margin: 2em 0;

	${props => (props.theme.css.hr ? props.theme.css.hr : '')}
`

export const UL = styled.ul`
	margin: 0.5em 1em 0.5em 2em;
	list-style-type: ${props => (props.isPlain ? 'none' : 'disc')};
	color: ${props => props.theme.colors.grey.dark().val};

	li {
		padding: 0.3em 0;
	}

	${props => (props.theme.css.ul ? props.theme.css.ul : '')}
`

export const OL = styled.ol`
	margin: 0.5em 1em 0.5em 2em;
	list-style-type: decimal;
	color: ${props => props.theme.colors.grey.dark().val};

	${props => (props.theme.css.ol ? props.theme.css.ol : '')}
`

export const EM = styled.em`
	${props => (props.theme.css.em ? props.theme.css.em : '')}
`

export const Strong = styled.strong`
	${props => (props.theme.css.strong ? props.theme.css.strong : '')}
`

const ctaStyles = css`
	width: ${props => (props.width ? props.width : 'auto')};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1em;
	text-transform: uppercase;
	font-size: 1.1em;
	white-space: nowrap;
	border: solid 2px;
	position: relative;
	&:hover {
		background: ${props => props.theme.colors.secondary.val};
		color: ${props => props.theme.colors.white.val};
		border-color: ${props => props.theme.colors.secondary.val};
		&:disabled {
			color: ${props => props.theme.colors.disabled.val};
			border-color: ${props => props.theme.colors.disabled.val};
			background: none;
		}
	}
	&:active {
		color: ${props => props.theme.colors.white.val};
		background: ${props => props.theme.colors.secondary.light('1').val};
		border-color: ${props => props.theme.colors.secondary.light('1').val};
	}

	${props => props.theme.media.tablet} {
		padding: 1em 2em;
	}
	${props => props.theme.media.sdesk} {
		font-size: 1.2em;
	}
`

export const btnStyles = css`
	&:active {
		border: none;
		color: ${props => props.theme.colors.secondary.light('1').val};
	}
	.icon {
		margin-right: 0.5em;
	}

	${props => (props.isCta ? ctaStyles : '')}

	&.cta {
		${ctaStyles}
	}

	${props => (props.theme.css.btnStyles ? props.theme.css.btnStyles : '')}
`

const Button = styled.button`
	border: none;
	outline: none;
	${btnStyles}
`
export const Btn = ({ onClick, prevDef = true, ...props }) => (
	<Button
		onClick={e => {
			if (prevDef && isFunction(get(e, 'preventDefault'))) e.preventDefault()
			if (isFunction(onClick)) onClick(e)
		}}
		{...props}
	/>
)

const linkStyles = css`
	.icon {
		margin-right: 0.5em;
		vertical-align: middle;
	}
`

const InternalLink = styled(ReachLink)`
	${linkStyles}
	&.cta {
		${btnStyles}
	}
`
const ExternalLink = styled.a`
	${linkStyles}
	&.cta {
		${btnStyles}
	}
`
export const Link = ({ to, isBlankTab, ...props }) =>
	isExternalLink(to) || isBlankTab ? (
		<ExternalLink
			href={to}
			target='_blank'
			rel='noopener noreferrer'
			className='link external-link'
			{...props}
		/>
	) : (
		<InternalLink
			to={to || '#'}
			target={isBlankTab && '_blank'}
			className='link internal-link'
			{...props}
		/>
	)
