import React from 'react'
import { createTheme, useTheme, ThemeProvider } from '@swfsoft/swf-theme'

const Themed = ({ Component, ...props }) => {
	const theme = useTheme()
	const swfTheme = theme ? null : createTheme({ context: 'SwF-UI - Themed.js' })
	return theme ? (
		<Component {...props} />
	) : (
		<ThemeProvider theme={swfTheme}>
			<Component {...props} />
		</ThemeProvider>
	)
}
Themed.propTypes = {}
export default Themed
