import React from 'react'
import { Fields, H5, Border } from '../UI'

const { Text, IconSelect, Checkbox } = Fields

const CTAFields = () => {
	return (
		<Border>
			<H5 mbot>CTA</H5>
			<Checkbox name='cta.hide' label='Hide CTA?' />
			<IconSelect name='cta.icon' label='CTA Icon' isReq />
			<Text name='cta.text' label='CTA Btn Text' />
			<Text name='cta.tagline' label='CTA Btn tagline (sub text)' />
			<Text name='cta.link' label='CTA Btn URL' />
		</Border>
	)
}
export default CTAFields
