import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fields, H5 } from '../../../../components/UI'

import SectionForm from './MCP.Event.section'

const { ArrayFields } = Fields

const Wrap = styled.div``

const Sections = ({ folderName }) => {
	return (
		<Wrap>
			<H5 className='banner'>Sections</H5>
			<ArrayFields
				name='sections'
				addText='Add Section'
				form={{
					selectDefaultTxt: 'Select type...',
					selectLabel: 'Content Type',
				}}
				forms={[
					{
						id: 'gen',
						txt: 'General Content',
						form: p => (
							<SectionForm
								formID='gen'
								defaultVals={{ icon: 'info', name: 'About', content: '' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'links',
						txt: 'List of Links',
						form: p => (
							<SectionForm
								formID='links'
								defaultVals={{ icon: 'handShake', name: 'Partners', links: [] }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'pics',
						txt: 'Photo Gallery',
						form: p => (
							<SectionForm
								formID='pics'
								{...p}
								defaultVals={{ icon: 'image', name: 'Photos', pics: [] }}
								eventCode={folderName}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'schedule',
						txt: 'Schedule Builder',
						form: p => (
							<SectionForm
								formID='schedule'
								defaultVals={{ icon: 'clock', name: 'Schedule', agenda: [], content: '' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'profiles',
						txt: 'Profiles Configuration',
						form: p => (
							<SectionForm
								formID='profiles'
								defaultVals={{ icon: 'folderTree', name: 'Profiles', type: 'profiles' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
				]}
			/>
		</Wrap>
	)
}
Sections.propTypes = {
	folderName: PropTypes.string.isRequired,
}
export default Sections
