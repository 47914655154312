/*
 =================================================
  Wraps Font Awesome Icons
  maps FA icons to contextful names (e.g, "err" = faExclamationCircle)
  for mappings see: fonts/fa-library.js
  Docs: https://fontawesome.com/icons
 =================================================
* */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { iconMap } from './fa.lib'

const I = styled.i`
	height: 1.2em;
	width: auto;
	display: inline-block;
	vertical-align: baseline;
	margin-left: ${props => (props.padLeft === true ? '1rem' : props.padLeft ? props.padLeft : 0)};
	margin-right: ${props =>
		props.padRight === true ? '1rem' : props.padRight ? props.padRight : 0};
	&& svg {
		width: inherit;
		height: inherit;
		color: inherit;
	}
`
const getIcon = type => {
	return iconMap[type] || iconMap.swf
}
const Icon = ({ type, padRight, padLeft, className, ...props }) => {
	const icon = getIcon(type)
	return (
		<I
			className={`icon ${className ? className : ''}`}
			padLeft={padLeft}
			padRight={padRight}
			{...props}
		>
			<FontAwesomeIcon icon={icon} />
		</I>
	)
}
Icon.propTypes = {
	type: PropTypes.string.isRequired,
}
export default Icon
