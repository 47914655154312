/* eslint-disable react-hooks/exhaustive-deps */
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'
import random from 'lodash/random'

import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useState } from '../../utils'

import Bar from './Bar'

const BarStyled = styled.div`
	width: 10%;
	max-width: 100%;
	min-width: 10%;
	height: 100%;
	transition: width ${props => `${props.theme.times.med}ms ${props.theme.times.ease}`};
	* {
		height: 100%;
	}
`

const BarWrap = React.memo(styled.div`
	width: 100%;
	height: ${props => (props.height ? props.height : '4px')};
	background-color: ${props =>
		props.theme.colors.loader
			? props.theme.colors.loader.light('6').val
			: props.theme.colors.grey.light('5').val};
	box-shadow: none;
	overflow: hidden;

	${props => (props.css ? props.css : '')}
`)

const BarContainer = ({ percentDone, isDone, height, className }) => {
	const [percent, setPercent] = useState(percentDone || 10, 'percent')

	const cancel = useRef(false)

	useEffect(() => {
		if (isNumber(percentDone) && percentDone > percent)
			// progress bar increments are being handled externally
			setPercent(round(percentDone))
	}, [percentDone])

	useEffect(() => {
		if (!isNumber(percentDone) && percent < 98) {
			// console.log('random percent use effect is firing | percentDone: ', percentDone, ' | percent: ', percent)
			// we are using random increments (no outside control)
			setTimeout(() => {
				// increment bar by amount between current percentage and current percentage + 5
				if (!cancel.current)
					setPercent(state => {
						const max = state < 98 ? state + (100 - state) * 0.25 : 99
						const min = state < 98 ? state + 0.2 : state + 0.01
						const newPercent = random(min, max)
						// console.log('min: ', state + 0.2, ' | max: ', max)
						// console.log('new percent: ', newPercent)
						return newPercent
					})

				// perform increments at random time intervals between 200 and 1000 milliseconds
			}, random(200, 2000, false))
		}
	}, [percent])

	useEffect(() => {
		return () => (cancel.current = true)
	}, [])

	return (
		<BarWrap className={`progress-bar${className ? ` ${className}` : ''}`} height={height}>
			<BarStyled className='bar' style={{ width: isDone ? '100%' : `${percent}%` }}>
				<Bar />
			</BarStyled>
		</BarWrap>
	)
}
BarContainer.propTypes = {
	isDone: PropTypes.bool,
	percentDone: PropTypes.number,
}
export default memo(BarContainer)
