import React from 'react'

import { Fields, H5, HR, P } from '../../../UI'
import LandingSectionHeroFeaturedItem from './Landing.section.hero.featureItem'

const { Select, ArrayFields } = Fields

const LandingSectionHeroFields = ({ folder }) => {
	return (
		<>
			<Select
				name='version'
				label='Hero Type'
				options={[
					{ txt: 'one', val: 'Version One' },
					{ txt: 'two', val: 'Version Two' },
				]}
			/>
			<HR />
			<H5 mbot>Featured Items</H5>
			<P>
				Featured Items are how content is added to a hero section. The first item will be the main
				hero content and the next 2-4 items after that will be displayed as columns underneath the
				first one.
			</P>
			<P>NOTE: Only the first 4 items will display on the page.</P>
			<ArrayFields
				name='featuredItems'
				addText='Add new item'
				form={{
					previewFields: ['headline'],
					form: p => <LandingSectionHeroFeaturedItem folder={folder} {...p} />,
				}}
			/>
		</>
	)
}
LandingSectionHeroFields.propTypes = {}
export default LandingSectionHeroFields
