/*
 =================================================
	Form chooser field
	NOTE: this may be able to be used outside of array fields
 =================================================
* */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import toString from 'lodash/toString'
import toNumber from 'lodash/toNumber'
import get from 'lodash/get'

import { Select } from '../../../FormFields'

import { useState } from '../../../../utils'

const Wrap = styled.div`
	.hide {
		display: none;
	}
`

const StepTwo = ({ Component, ...props }) => (
	<div className='form-render-container'>{Component && <Component {...props} />}</div>
)

const ArrayFieldsMultiForm = ({
	selectLabel = 'Form option',
	selectDefaultTxt = 'Select an option...',
	submitHandler,
	forms,
}) => {
	const [formIdx, setFormIdx] = useState(null, 'formIdx')

	const formOptions = forms.map(({ txt }, idx) => ({ txt, val: toString(idx) }))

	const SelectedForm = formIdx !== null ? forms[toNumber(formIdx)].form : null

	const handleSubmit = val => {
		const formID = forms[toNumber(formIdx)].id
		if (get(val, 'vals')) val.vals.formID = formID
		else val.formID = formID
		submitHandler(val)
	}

	return (
		<Wrap>
			<Select
				defaultTxt={selectDefaultTxt}
				name='form-option-select'
				label={selectLabel}
				options={formOptions}
				onChange={e => setFormIdx(e.target.value)}
			/>
			<StepTwo Component={SelectedForm} submitHandler={handleSubmit} />
		</Wrap>
	)
}

ArrayFieldsMultiForm.propTypes = {
	forms: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			form: PropTypes.func.isRequired,
			txt: PropTypes.string.isRequired,
		})
	).isRequired,
	submitHandler: PropTypes.func.isRequired,
}
export default ArrayFieldsMultiForm
