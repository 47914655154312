import React from 'react'
import map from 'lodash/map'

import { Fields } from '../../../UI'
import config from '../../../../styles/theme.config'

const { RT, ArrayFields, Checkbox, Text, Textarea, UploadField } = Fields

const LandingSectionPersonnelFields = () => {
	return (
		<>
			<RT
				name='content'
				label='Content (Above Team list)'
				colors={map(config.colors, val => val)}
			/>
			<ArrayFields name='personnel' label='Person' addText='Add person'>
				<Checkbox name='hide' label='Hide person?' default={false} />
				<Text name='name' label='Full Name' isPreview isReq />
				<Text name='title' label={`Title (e.g. 'Director of Awesomeness')`} />
				<Text name='org' label='Organization (Leave blank for TPA)' />
				<Textarea name='bio' label='Bio' isReq />
				<UploadField name='pic' label='Profile Pic' />
			</ArrayFields>
		</>
	)
}
export default LandingSectionPersonnelFields
