import React from 'react'
import styled from 'styled-components'
// import { Router } from '@reach/router'

import { Link } from '../UI'

const Nav = styled.nav``

const LoggedInNav = props => {
	return (
		<Nav {...props}>
			<li path='logout'>
				<Link to='/logout'>Logout</Link>
			</li>
		</Nav>
	)
}
LoggedInNav.propTypes = {}
export default LoggedInNav
