import React from 'react'

import { Fields, H5 } from '../../UI'

const { Text, Textarea, Select, UploadField } = Fields

const LandingSEOForm = () => {
	return (
		<>
			<H5>Sections</H5>
			<Select
				name='seo.type'
				label='SEO Content Type'
				options={[{ txt: 'General', val: 'WebPage' }]}
			/>
			<Text name='seo.title' label='Browser/SEO meta title' isReq />
			<Textarea name='seo.description' label='SEO meta Description' isReq />
			<UploadField name='seo.image' label='SEO meta image (Usually an icon or logo)' />
			<Text
				name='seo.keywordString'
				label='SEO meta keywords (enter keywords separated by a comma)'
			/>
			{/* <SingleArrayField name='seo.keywords' label='SEO meta keywords (Add one per line)' /> */}
		</>
	)
}
LandingSEOForm.propTypes = {}
export default LandingSEOForm
