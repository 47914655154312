import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import ArrayItem from './ArrayFields.item'

const PreviewList = styled.div``

const ArrayFieldsPreviews = ({
	name,
	arrayItems,
	values,
	dragDropHandler,
	selectHandler,
	removeHandler,
	selectedIdx,
}) => {
	const dropHandler = ({ source, destination }) => {
		if (
			!destination ||
			(destination.droppableId === source.droppableId && destination.index === source.index)
		)
			return

		let newArry = [...values]
		const [item] = newArry.splice(source.index, 1)
		newArry.splice(destination.index, 0, item)
		dragDropHandler(newArry)
	}

	return (
		<DragDropContext onDragEnd={dropHandler}>
			<Droppable droppableId={name}>
				{({ droppableProps, placeholder, innerRef }) => (
					<PreviewList ref={innerRef} {...droppableProps}>
						{arrayItems &&
							arrayItems.map(({ vals, previewFields }, idx) => (
								<ArrayItem
									key={idx}
									idx={idx}
									id={`preview-item-${idx}`}
									selectHandler={() => {
										selectHandler(vals, idx)
									}}
									remove={() => removeHandler(idx)}
									isSelected={selectedIdx === idx}
									previewFields={previewFields}
									vals={vals}
								/>
							))}
						{placeholder}
					</PreviewList>
				)}
			</Droppable>
		</DragDropContext>
	)
}
ArrayFieldsPreviews.propTypes = {
	name: PropTypes.string.isRequired,
	arrayItems: PropTypes.arrayOf(
		PropTypes.shape({
			vals: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.number]),
			previewFields: PropTypes.arrayOf(
				PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string, PropTypes.number])
			),
		})
	),
	values: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.number])
		),
		PropTypes.string,
	]),
	dragDropHandler: PropTypes.func.isRequired,
	selectHandler: PropTypes.func.isRequired,
	removeHandler: PropTypes.func.isRequired,
	selectedIdx: PropTypes.number,
}
export default ArrayFieldsPreviews
