import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import Icon from '../../../../Icons'

const PreviewItem = styled.div`
	margin-right: 1em;
	text-overflow: ${props => (props.isTrunc ? 'ellipsis' : 'clip')};
	overflow: ${props => (props.isTrunc ? 'hidden' : 'initial')};
	&:last-child {
		margin-right: 0;
	}
	.pic {
		width: 100px;
		img {
			display: block;
			max-width: 100%;
		}
	}
`

const ArrayFieldsPreview = ({
	children,
	type = 'string',
	name,
	iconType,
	defaultContent,
	...props
}) => {
	return (
		<PreviewItem {...props}>
			{type === 'icon' ? (
				<Icon type={iconType ? iconType : defaultContent || children} />
			) : type === 'pic' && get(children, 'thumb.url') ? (
				<div className='pic'>
					<img src={children.thumb.url} alt='' />
				</div>
			) : (
				<div className='string'>{children || defaultContent || 'N/A'}</div>
			)}
		</PreviewItem>
	)
}
ArrayFieldsPreview.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	iconType: PropTypes.string,
}
export default ArrayFieldsPreview
