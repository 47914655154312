import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import ReactModal from 'react-modal'

import { Btn, H3, EM } from '../Typography'
import Icon from '../Icons'

ReactModal.setAppElement('#root')

const ModalInnerWrap = styled.div`
	width: 100%;
	height: 100%;
	max-height: 70vh;
	padding: 1em;
	overflow: hidden;
	${props => props.theme.media.tablet} {
		padding: 1em 2em;
		max-width: 80vw;
	}
	.content {
		height: 70vh;
		& > * {
			height: 70vh;
		}
		.form-render-container {
			height: 60vh;
			& > * {
				height: 100%;
			}
		}
	}
	&.__scroll-overflow {
		overflow-y: auto;
		.content {
			height: auto;
			& > * {
				height: auto;
			}
			.form-render-container {
				height: auto;
				& > * {
					height: auto;
				}
			}
		}
	}
	&.pic {
		figure {
			img {
				display: block;
				max-width: 100%;
				max-height: 60vh;
			}
			figcaption {
				width: 100%;
				padding: 0.5em 1em;
				background: ${props => props.theme.colors.black.val};
				color: ${props => props.theme.colors.white.val};
				box-sizing: border-box;
				span,
				em {
					display: block;
					${props => props.theme.media.tablet} {
						display: initial;
					}
				}
				em {
					margin-left: 1.5em;
				}
			}
		}
	}
	.scrollable {
		height: 100%;
		padding: 0;
		& > header {
			height: 90px;
			display: flex;
			padding-bottom: 15px;
			.submit-btn {
				margin-left: auto;
			}
		}
		& > .scroll {
			height: calc(85% - 100px);
			overflow-y: auto;
			.scroll-child {
				padding: 50px 0;
				padding-right: 30px;
			}
		}
	}
	.modal-footer {
		justify-content: center;
		width: 100%;
		.alert-btns {
		}
	}
`

const Modal = ({
	children,
	closeHandler,
	titleText,
	SubmitBtn,
	size,
	canBgClose = true,
	confirm,
	type,
	file,
	caption,
	shouldHandleScroll = true,
	...props
}) => {
	return (
		<ReactModal
			shouldCloseOnOverlayClick={canBgClose}
			onRequestClose={closeHandler}
			{...props}
			className={`modal ${type || ''} ${size || 'small'}`}
			overlayClassName={`flex overlay modal-overlay ${type || ''}`}
		>
			<header className='flex modal-header'>
				{titleText && <H3>{titleText}</H3>}
				{SubmitBtn ? <div className='modal-submit-btn'>{SubmitBtn}</div> : null}
				<Btn
					className='modal-close-btn'
					onClick={e => {
						e.preventDefault()
						closeHandler()
					}}
				>
					<Icon type='x' />
				</Btn>
			</header>
			<ModalInnerWrap className={`${type || ''}${shouldHandleScroll ? ' __scroll-overflow' : ''}`}>
				{type === 'pic' && file ? (
					<figure>
						<img src={get(file, 'full.url')} alt={get(file, 'description')} />
						<figcaption>
							<span className='name'>{get(file, 'name')}</span>
							{get(file, 'description') && <EM>{file.description}</EM>}
						</figcaption>
					</figure>
				) : (
					<>
						{children && <div className='content'>{children}</div>}
						<footer className='flex modal-footer'>
							{type === 'alert' && (
								<div className='flex alert-btns'>
									<Btn
										className='alert-confirm-btn'
										onClick={e => {
											e.preventDefault()
											confirm()
										}}
										isCta
									>
										Okay
									</Btn>
									<Btn
										className='alert-cancel-btn'
										onClick={e => {
											e.preventDefault()
											closeHandler()
										}}
									>
										Cancel
									</Btn>
								</div>
							)}
						</footer>
					</>
				)}
			</ModalInnerWrap>
		</ReactModal>
	)
}
Modal.propTypes = {
	type: PropTypes.string,
	closeHandler: PropTypes.func.isRequired,
	confirm: PropTypes.func,
}
export default React.memo(Modal)
