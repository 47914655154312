import React from 'react'
import * as Yup from 'yup'

import { Fields } from '../UI'

const { Text, UploadField } = Fields

export const SponsorSchema = Yup.object().shape({
	orgName: Yup.string().required('Please enter a name for this sponsor'),
	logo: Yup.object().required('Please upload a logo image'),
})

const SponsorFormFields = () => {
	return (
		<>
			<Text name='orgName' label='Name' isReq />
			<Text name='link' label='Link (website URL)' />
			<UploadField name='logo' label='Logo (PNGs are best)' allowedFolders='sponsor-logos' isReq />
		</>
	)
}
export default SponsorFormFields
