import React from 'react'
import { Redirect, Router } from '@reach/router'

import Protect from '../components/Protect'
import Page404 from '../pages/404'

import Dash from '../pages/MCP/Dash'
import Pages from '../pages/MCP/MCP.Pages'
import Page from '../pages/MCP/MCP.Page'
import Events from '../pages/MCP/MCP.Events'
import Event from '../pages/MCP/MCP.Event'
import Sponsors from '../pages/MCP/MCP.Sponsors'
import Sponsor from '../pages/MCP/MCP.Sponsor'
import MediaLibrary from '../pages/MCP/MCP.mediaLibrary'

const MCPRoutes = () => {
	return (
		<Protect allowed={['sys_mgr']} redirect='/'>
			<Router>
				<Redirect from='/' to='dash' noThrow />
				<Dash path='dash' />
				<Pages path='pages' />
				<Page path='page' />
				<Events path='events' />
				<Events path='events/:id' />
				<Event path='event' />
				<Sponsors path='sponsors' />
				<Sponsor path='sponsor' />
				<MediaLibrary path='media-library' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
MCPRoutes.propTypes = {}
export default MCPRoutes
