import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'
import get from 'lodash/get'
import { useState } from '../utils'

import Block from '../Block'
import { Btn } from '../Typography'

import FileUploadWrap from './FileUploader.view'
import Upload from './FileUploader.upload'
import Folders from './FileUploader.folders'
import Library from './FileUploader.library'
import FileOptions from './FileUploader.options'

const FileUploader = ({ cb, disableCB, folder: initialFolder = 'misc', allowedFolders, api }) => {
	const [folder, setFolder] = useState(initialFolder, 'folder')
	const [files, setFiles] = useState([], 'files')
	const [selectedFile, setSelectedFile] = useState(null, 'selectedFile')
	const [error, setError] = useState(null, 'error')
	const [isUploading, setUploading] = useState(false, 'isUploading')
	// const [isCancelled, setIsCancelled] = useState(false, 'isCancelled')
	// const [progress, setProgress] = useState(null, 'progress')

	const {
		upload: uploadHandler,
		update: updateFileHandler,
		get: fetchFiles,
		getFolders: fetchFolders,
		delete: deleteFiles,
	} = api

	const handleSelect = useCallback(
		file => {
			setSelectedFile(isString(file) ? files.find(f => f.id === file) : file)
		},
		[files]
	)

	const handleUploading = file => {
		if (file === true) {
			// uploading has begun
			setUploading(true)
			disableCB(true)
		} else if (file) {
			// uploading has completed successfully
			setFiles(state => [...state, file])
			setSelectedFile(file)
			setUploading(false)
			disableCB(false)
		} else {
			// uploading failed
			setUploading(false)
			disableCB(false)
		}
	}

	const handleCB = e => {
		e.preventDefault()
		if (cb) cb(selectedFile)
	}

	const handleFileUpdate = file => {
		setFiles(state => [...state.map(f => (f.id === file.id ? file : f))])
		handleSelect(file)
	}

	const handleSelectFolder = folderName => {
		setSelectedFile(null)
		setFolder(folderName)
	}

	return (
		<FileUploadWrap className='file-upload-wrap'>
			<header>
				<Upload
					uploadHandler={uploadHandler}
					uploadingHandler={handleUploading}
					setError={setError}
					folder={folder}
				/>
				{error && <Block type='err'>{error}</Block>}
				<div className='action-bar flex'>
					{!!cb ? (
						<Btn onClick={handleCB} className='cta' disabled={!selectedFile || isUploading}>
							Use Selected
						</Btn>
					) : null}
				</div>
			</header>
			<div className='action-area flex'>
				{isUploading && <Block usage='overlay' />}
				<main className='library-section flex-tablet'>
					<Folders
						handleSelect={handleSelectFolder}
						selectedFolder={folder}
						allowedFolders={allowedFolders}
						fetchFolders={fetchFolders}
					/>
					<Library
						selectHandler={handleSelect}
						selectedId={get(selectedFile, 'id')}
						files={files}
						setFiles={setFiles}
						setError={setError}
						folder={folder}
						fetchFiles={fetchFiles}
						deleteFiles={deleteFiles}
					/>
				</main>
				<aside className='file-options'>
					<FileOptions
						file={selectedFile}
						submitHandler={handleFileUpdate}
						folder={folder}
						updateFileHandler={updateFileHandler}
					/>
				</aside>
			</div>
		</FileUploadWrap>
	)
}
FileUploader.propTypes = {
	folder: PropTypes.string,
	allowedFolders: PropTypes.arrayOf(PropTypes.string),
	cb: PropTypes.func,
	api: PropTypes.shape({
		get: PropTypes.func.isRequired,
		update: PropTypes.func.isRequired,
		delete: PropTypes.func.isRequired,
		getFolders: PropTypes.func.isRequired,
		upload: PropTypes.func.isRequired,
	}),
}
export default React.memo(FileUploader)
