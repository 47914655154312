import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TabNav from './Tab.nav'
import { Spinner } from '../Loaders'
import { useState } from '../utils'

const Wrap = styled.div`
	transition: all 500ms ease-in-out;
	&.pre-render {
		.tabs-wrap {
			display: flex;
			max-width: 100%;
			overflow: hidden;
		}
	}
	.tab-wrap {
		padding-top: 1em;
		min-height: 150px;
	}
`

const TabWrap = ({ children, isActive, preRender = false }) => (
	<div
		className={isActive ? `tab-wrap active` : `tab-wrap`}
		style={preRender ? { display: isActive ? 'block' : 'none' } : {}}
	>
		{children}
	</div>
)

const Tabs = ({ children: tabs, initialTabIdx = 0, renderOnSelect = true }) => {
	const [currentIdx, setCurrentIdx] = useState(initialTabIdx, 'currentIdx')

	const CurrentTab = tabs[currentIdx]

	return (
		<Wrap className={renderOnSelect ? `tabs` : `tabs pre-render`}>
			<TabNav tabs={tabs} currentIdx={currentIdx} selectHandler={setCurrentIdx} />
			{tabs ? (
				<div className='tabs-wrap'>
					{renderOnSelect ? (
						<TabWrap isActive>{[CurrentTab]}</TabWrap>
					) : (
						tabs.map((Tab, idx) => (
							<TabWrap
								key={Tab.props.nav || `tab-${idx}`}
								isActive={idx === currentIdx}
								preRender={!renderOnSelect}
							>
								{[Tab]}
							</TabWrap>
						))
					)}
				</div>
			) : (
				<Spinner />
			)}
		</Wrap>
	)
}
Tabs.propTypes = {
	children: PropTypes.arrayOf(
		PropTypes.shape({
			props: PropTypes.shape({
				nav: PropTypes.string.isRequired,
			}),
		})
	),
	initialTabIdx: PropTypes.number,
	renderOnSelect: PropTypes.bool,
}
export default Tabs
