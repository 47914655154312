import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import { FieldArray } from 'formik'

import { useState } from '../../../utils'
import { Btn } from '../../../Typography'
import Modal from '../../../Modal'
import Label from '../Label'

import Previews from './ArrayFields.previews'
import ArrayFieldsForm from './ArrayFields.form'
import MultiForm from './ArrayFields.MultiForm'

const ArrayWrap = styled.div`
	label {
		margin-bottom: 1em;
	}
	.add-item-btn {
		margin: 0.5em 0;
		margin-left: auto;
		padding: 0.5em 1em;
		display: block;
	}
`

const createPreviews = FormFields =>
	FormFields.filter(({ props }) => props.isPreview).map(({ props }) => props)

const ArrayFields = ({
	name,
	children,
	form,
	forms,
	label,
	addText = 'Add Item',
	saveItemText = 'Save',
	saveNewText = 'Add',
	...props
}) => {
	const FormFields = isArray(children) ? children : get(children, 'props.children')

	// console.log(`FormFields for ${name}:`, children)
	// console.log(`form for ${name}: `, form)

	const [currentItem, setCurrentItem] = useState(null, 'currentItem')
	const [lastIdx, setLastIdx] = useState(null, 'lastIdx')
	const [currentForm, setCurrentForm] = useState(form, 'currentForm')

	const previews = get(form, 'previewFields')
		? form.previewFields
		: FormFields && createPreviews(FormFields)

	const CurrentForm = currentForm && currentForm.form

	const formOptions = isPlainObject(form) ? form : {}

	return (
		<ArrayWrap>
			{label && <Label>{label}</Label>}
			<FieldArray
				{...props}
				name={name}
				render={({ remove, push, form: parentFormikForm }) => {
					const { setFieldValue, values } = parentFormikForm
					const arrayItems =
						values && values[name]
							? values[name].map(item => ({
									vals: item,
									previewFields:
										previews ||
										(item.formID && forms && forms.find(({ id }) => id === item.formID))
											.previewFields,
							  }))
							: null

					const updateItemHandler = vals => {
						if (isNumber(get(currentItem, 'idx')))
							setFieldValue(`${name}.${get(currentItem, 'idx')}`, vals)
						else push(vals)

						setCurrentItem(null)
						setCurrentForm(form || null)
					}

					const selectItemHandler = (vals, idx) => {
						if (forms && vals.formID)
							setCurrentForm({
								vals,
								form: forms.find(({ id }) => id === vals.formID).form,
							})

						setCurrentItem({ idx, vals })
						setLastIdx(idx)
					}

					return (
						<>
							<Previews
								name={name}
								arrayItems={arrayItems}
								values={values[name]}
								dragDropHandler={newArry => setFieldValue(name, newArry)}
								selectedIdx={lastIdx}
								selectHandler={selectItemHandler}
								removeHandler={idx => remove(idx)}
							/>
							<Btn
								className='add-item-btn'
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									setCurrentItem({})
									setLastIdx(values.length - 1)
								}}
							>
								{addText}
							</Btn>
							<Modal
								isOpen={!!currentItem}
								closeHandler={() => {
									setCurrentItem(null)
									setCurrentForm(form || null)
								}}
								size='large'
								canBgClose={false}
								shouldHandleScroll={false}
							>
								{CurrentForm ? (
									<CurrentForm
										submitHandler={updateItemHandler}
										values={currentItem && currentItem.vals}
										{...formOptions}
									/>
								) : forms ? (
									<MultiForm submitHandler={updateItemHandler} forms={forms} {...formOptions} />
								) : (
									<ArrayFieldsForm
										titleText={get(form, 'legend', label)}
										schema={get(form, 'schema')}
										addBtnText={get(currentItem, 'idx') ? 'Update' : 'Add'}
										submitHandler={updateItemHandler}
										values={currentItem && currentItem.vals}
										FormFields={FormFields}
										saveNewText={get(formOptions, 'saveNewText')}
										saveExistingText={get(formOptions, 'saveExistingText')}
									/>
								)}
							</Modal>
						</>
					)
				}}
			/>
		</ArrayWrap>
	)
}
ArrayFields.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	form: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.shape({
			legend: PropTypes.string,
		}),
	]),
	// forms: PropTypes.arrayOf(PropTypes.node),
}
export default ArrayFields
