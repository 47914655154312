import { useRef, useEffect } from 'react'
import isFunction from 'lodash/isFunction'
import useState from './useState'

const useAsync = (cb, options = {}) => {
	const isCalled = useRef(false)
	const [isWorking, setIsWorking] = useState(true, 'isWorking')
	const [res, setRes] = useState(null, 'res')
	const [err, setErr] = useState(null, 'err')
	const canceled = useRef(false)
	const callTimes = useRef(0)

	const { displayErr } = options

	useEffect(() => {
		const callFunc = async () => {
			try {
				// callTimes.current = callTimes.current + 1
				if (!isFunction(cb)) return
				setIsWorking(true)
				// console.log('useAsync calling func: ', func, ' with args: ', ...args)
				const result = await cb()
				// console.log('useAsync res: ', result)
				if (canceled.current) return null
				setRes(result)
				setIsWorking(false)
				isCalled.current = false
			} catch (error) {
				if (canceled.current) {
					if (displayErr) console.error('useAsync error for canceled operation: ', error)
					return null
				}
				setErr(error)
				setIsWorking(false)
				isCalled.current = false
				console.error('useAsync error: ', error)
			}
		}
		if (!isCalled.current && callTimes.current < 4 && !res) {
			isCalled.current = true
			callFunc()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cb, res])

	return {
		isWorking,
		res,
		err,
		cancel: () => {
			canceled.current = true
		},
	}
}

export default useAsync
