import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isFunction from 'lodash/isFunction'

import Icon from '../Icons'

const Wrap = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	.bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		background: ${props => props.theme.colors.white.tint().val};
	}
	.close-btn {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		color: ${props => props.theme.colors.grey.val};
		border: none;
		outline: none;
		z-index: 11;
		&:focus {
			outline: none;
		}
		&:hover {
			color: ${props => props.theme.colors.grey.light().val};
		}
		.icon {
			height: 20px;
			${props => props.theme.media.tablet} {
				height: 30px;
			}
		}
	}
	.block-content {
	}
`

const BlockOverlay = ({ close, bgClose = true, children }) => {
	const closeHandler = () => (isFunction(close) ? close() : null)

	return (
		children && (
			<Wrap>
				{!!close && bgClose ? (
					<div className='bg' onClick={() => closeHandler()} aria-hidden={true} />
				) : (
					<div className='bg'></div>
				)}
				{!!close && (
					<button className='close-btn' onClick={() => closeHandler()}>
						<Icon type='x' />
					</button>
				)}
				{children}
			</Wrap>
		)
	)
}
BlockOverlay.propTypes = {
	close: PropTypes.func,
}
export default React.memo(BlockOverlay)
