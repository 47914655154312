import React, { useEffect } from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { Redirect } from '@reach/router'

import { useAsync } from '../../../utils'
import { mcp } from '../../../api'

import { Board, Table, Link } from '../../../components/UI'

const Wrap = styled.div`
	tr {
		td {
			padding: 0 1em;
			${props => props.theme.media.sdesk} {
				padding: 0.5em 1em;
			}
			a {
				padding-bottom: 1em;
				padding-top: 1em;
				display: block;
				text-align: left;
				${props => props.theme.media.sdesk} {
					padding: 0;
				}
			}
		}
	}
`

const MCPEvents = ({ id }) => {
	const { res, isWorking, err, cancel } = useAsync(() => {
		return mcp.events.get({ orgID: '5ecafd5a1480065f3fbab3ac' })
	})

	useEffect(() => {
		return () => cancel()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const rows = isEmpty(res)
		? null
		: res
				.sort(({ date: a }, { date: b }) => (a < b ? -1 : a > b ? 1 : 0))
				.map(({ id, name, hide }) => ({
					id,
					cols: [
						<Link to={`/mcp/events/${id}`}>
							{name} {hide ? ' [HIDDEN]' : ''}
						</Link>,
					],
				}))

	return id ? (
		<Redirect to={`/mcp/event?id=${id}`} noThrow />
	) : (
		<Wrap>
			<Board titleText='Events'>
				<Table
					title='TPA Events'
					Err={err ? err.msg : null}
					head={{
						cols: ['Event'],
					}}
					colsQty={1}
					Empty={!isWorking && !err && !rows && <em>No events found...</em>}
					body={{
						rows,
					}}
				/>
				<Link to='/mcp/event' className='cta'>
					Create Event
				</Link>
			</Board>
		</Wrap>
	)
}
MCPEvents.propTypes = {}
export default MCPEvents
