import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Spinner from './Spinner'
import ProgressBar from './ProgressBar'

const LoaderWrap = styled.div`
	min-height: 20vh;
`

const progressCSS = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`

const LoaderHOC = ({ isDone, Loader, useProgressBar, children }) => {
	const LoaderComponent = Loader || Spinner
	return (
		<>
			{useProgressBar && (
				<ProgressBar css={progressCSS} isDone={isDone} className='pageload-progress-bar' />
			)}
			{isDone
				? children
				: !useProgressBar && (
						<LoaderWrap className='loader-wrap'>
							<LoaderComponent />
						</LoaderWrap>
				  )}
		</>
	)
}
LoaderHOC.propTypes = {
	isDone: PropTypes.bool,
	Loader: PropTypes.node,
	useProgressBar: PropTypes.bool,
}
export default React.memo(LoaderHOC)
