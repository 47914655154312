import React from 'react'
import { Fields } from '../../../../components/UI'
import { UploadField } from '../../../../components/UI/Forms/FormFields'

const { Text, ArrayFields, Checkbox } = Fields

const MCPEventSectionLinks = () => {
	return (
		<ArrayFields name='links' label='Links'>
			<Checkbox name='hide' label='Hide?' default={false} />
			<Text name='txt' label='Link text' isPreview isReq />
			<Text name='to' label='Link URL' isReq />
			<UploadField name='img' label='Logo (.pngs are best)' />
		</ArrayFields>
	)
}
MCPEventSectionLinks.propTypes = {}
export default MCPEventSectionLinks
