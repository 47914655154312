import React from 'react'
import { Fields } from '../../UI'

const { Text, Textarea, Checkbox } = Fields

const LandingInfoForm = ({ isNewPage }) => {
	return (
		<>
			<Checkbox name='hide' label='Hide page? (will not show up on the website or nav menus)' />
			{isNewPage ? <Text name='slug' label='Slug (URL path)' isReq /> : null}
			<Text name='name' label='Page Name (Internal Use)' isReq />
			<Textarea name='description' label='Description (Internal Use)' />
		</>
	)
}
LandingInfoForm.propTypes = {}
export default LandingInfoForm
