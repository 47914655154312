/*
 =================================================
  Container node for restricting access to components
 =================================================
* */

import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from '@reach/router'

import { useProtect } from '../utils'

import { Block, LoaderHOC } from './UI'

const Protect = ({
	children,
	enforce = true,
	allowed,
	Denied = (
		<Block usage='overlay' type='err'>
			You do not have access
		</Block>
	),
	Loader,
	redirect,
	path,
}) => {
	const [isAuthed, isDone] = useProtect({ path, allowed })

	// console.log('Protect - hasAuthed:', hasAuthed, ' | isAuthed: ', isAuthed)

	const NoAccess = () => (redirect ? <Redirect to={redirect} noThrow /> : Denied)

	const shouldRenderRoute = isAuthed || enforce === false

	return (
		<LoaderHOC
			isDone={isDone}
			Loader={Loader}
			useProgressBar={true}
			children={shouldRenderRoute ? children : <NoAccess />}
		/>
	)
}
Protect.propTypes = {
	path: PropTypes.string,
	Loader: PropTypes.node,
	enforce: PropTypes.bool,
	allowed: PropTypes.arrayOf(PropTypes.string),
}
export default Protect
