import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Yup from 'yup'
import defaultsDeep from 'lodash/defaults'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { Fields, Form } from '../../../UI'

import LandingSectionHero from './Landing.section.hero'
import LandingSectionGen from './Landing.section.gen'

import config from '../../../../styles/theme.config'
import LandingSectionEventListFields from './Landing.section.eventList'
import LandingSectionPersonnelFields from './Landing.section.personnel'
import LandingSectionFormFields from './Landing.section.form'
import LandingSectionDisclaimerFields from './Landing.section.disclaimer'
import LandingSectionSponsorFields from './Landing.section.sponsors'

const { Text, SubmitBtn, Checkbox, RT } = Fields

const LandingPageSectionFormView = styled.div`
	form {
	}
`

const LandingPageSectionForm = ({
	submitHandler,
	label = 'Page Section',
	values = {},
	defaultVals = {},
	schema: schma = {},
	formID,
}) => {
	const sectionDefaultVals = {
		type: formID,
		hide: false,
		hideOnStage: false,
		name: '',
		sectionTitle: '',
		hideSectionTitle: false,
	}

	const initialVals = defaultsDeep(values, defaultVals, sectionDefaultVals)

	const schema = Yup.object({
		name: Yup.string().required('Please enter a section title'),
		icon: Yup.string().required('Please choose an icon'),
		...schma,
	})

	return (
		<LandingPageSectionFormView>
			<Form
				initialValues={initialVals}
				validationSchema={schema}
				onSubmit={vals => {
					submitHandler(vals)
				}}
				className='scrollable'
			>
				{({ isValid }) => (
					<>
						<header>
							{label ? <legend>{label}</legend> : null}
							<SubmitBtn isValid={isValid}>
								{isEmpty(values) ? 'Add Section' : 'Save Section'}
							</SubmitBtn>
						</header>
						<div className='scroll'>
							<div className='scroll-child'>
								<Checkbox
									name='hide'
									label='Hide on live site? (will still show up on staging site)'
								/>
								<Checkbox
									name='hideOnStage'
									label='Hide on staging site? (will still show up on live site)'
								/>
								<Text name='name' label='Section name (Internal use only)' isReq />
								<Text name='sectionTitle' label='Section title' />
								<Checkbox name='hideSectionTitle' label='Hide section title?' />
								<>
									{formID === 'hero' ? (
										<LandingSectionHero />
									) : formID === 'gen' ? (
										<LandingSectionGen />
									) : formID === 'events' ? (
										<LandingSectionEventListFields />
									) : formID === 'personnel' ? (
										<LandingSectionPersonnelFields />
									) : formID === 'form' ? (
										<LandingSectionFormFields />
									) : formID === 'disclaimer' ? (
										<LandingSectionDisclaimerFields />
									) : formID === 'sponsor' ? (
										<LandingSectionSponsorFields />
									) : (
										<RT name='content' label='Content' colors={map(config.colors, val => val)} />
									)}
								</>
							</div>
						</div>
					</>
				)}
			</Form>
		</LandingPageSectionFormView>
	)
}
LandingPageSectionForm.propTypes = {
	submitHandler: PropTypes.func.isRequired,
}
export default LandingPageSectionForm
