import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAsync } from '../../../utils'
import { Spinner } from '../../../Loaders'
import Select from '../Select'
import { mcp } from '../../../../../api'

const SponsorSelectFieldView = styled.div``

const SponsorSelectField = ({ name, label, isReq }) => {
	const { res, isWorking, cancel } = useAsync(() => mcp.sponsors.get())

	useEffect(() => {
		return () => {
			cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const options = res
		?.filter(({ hide }) => !hide)
		.map(({ id, orgName }) => ({
			txt: orgName,
			val: id,
		}))

	if (options?.length)
		options.unshift({
			txt: 'Select a sponsor',
			val: '',
		})

	return (
		<SponsorSelectFieldView>
			{label ? (
				<label>
					{isReq ? '*' : ''}
					{label}
				</label>
			) : null}
			{isWorking ? <Spinner /> : res?.length ? <Select name={name} options={options} /> : null}
		</SponsorSelectFieldView>
	)
}
SponsorSelectField.propTypes = {}
export default SponsorSelectField
