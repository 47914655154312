import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { Btn } from '../Typography'
import Modal from '../Modal'
import { useState } from '../utils'

const Preview = styled(Btn)`
	width: ${props => (props.isFull ? '150px' : '100px')};
	height: ${props => (props.isFull ? '150px' : '100px')};
	background: ${props => props.theme.colors.grey.light('6').val};
	align-items: center;
	justify-content: center;
	padding: 0;
	${props => props.theme.media.tablet} {
		width: ${props => (props.isFull ? '200px' : '100px')};
		height: ${props => (props.isFull ? '200px' : '100px')};
	}
	${props => props.theme.media.sdesk} {
		width: ${props => (props.isFull ? '300px' : '100px')};
		height: ${props => (props.isFull ? '300px' : '100px')};
	}
	img {
		max-width: 100%;
		max-height: 100%;
		display: block;
	}
`

const FilePreview = ({ file, isFull }) => {
	const [showModal, setShowModal] = useState(false, 'showModal')

	const clickHandler = e => {
		e.preventDefault()
		setShowModal(true)
	}

	const url = isFull ? get(file, 'mobile.url', get(file, 'preview.url')) : get(file, 'preview.url')

	return (
		<>
			<Preview className='file-preview flex' isFull={isFull} onClick={clickHandler}>
				<img src={url} alt={get(file, 'description', get(file, 'name'))} />
			</Preview>
			<Modal
				isOpen={showModal}
				closeHandler={() => setShowModal(false)}
				size='large'
				file={file}
				type='pic'
			/>
		</>
	)
}
FilePreview.propTypes = {
	file: PropTypes.shape({
		preview: PropTypes.shape({
			url: PropTypes.string.isRequired,
		}),
		name: PropTypes.string.isRequired,
	}),
}
export default FilePreview
