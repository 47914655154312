/*
 =================================================
	To add Font Awesome icons to app:
	1. import them from the appropriate library (light, regular, other)
	2. add the imports to the create library function
	3. add the mapping for that icon in iconMap
 =================================================
* */
import defaults from 'lodash/defaults'
import uniqBy from 'lodash/uniq'
import { library } from '@fortawesome/fontawesome-svg-core'
import { defaultMap, defaultLib } from './fa.defaults'

// Used by Icons.js for contextual access to icons used in this app
export let iconMap = defaultMap

export const createLibrary = (opts = {}) => {
  const { library: lib = [], iconMap: iMap = {} } = opts

  // generate new iconMap using incoming iconMap
  iconMap = defaults(iMap, defaultMap)

  // 1. concat default library with incoming library
  // 2. get rid of duplicates
  const libIcons = uniqBy([
    ...defaultLib,
    ...lib
  ], 'iconName')

  library.add(libIcons)
}


