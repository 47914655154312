import React from 'react'
import {useFormikContext} from 'formik'
import { Btn } from '../../Typography'
import { Spinner } from '../../Loaders'

const SubmitButton = ({
	loader = true,
	children,
	isCta = true,
	...props
}) => {
	const {isSubmitting, isValid} = useFormikContext()
	return (
		<Btn
			disabled={isSubmitting || !isValid}
			className='submit-btn'
			isCta={isCta}
			prevDef={false}
			{...props}
			type='submit'
		>
			{isSubmitting && loader && <Spinner />}
			{children || 'Submit'}
		</Btn>
	)
}
export default SubmitButton
