import React from 'react'
import PropTypes from 'prop-types'
import defaultsDeep from 'lodash/defaultsDeep'
import * as Yup from 'yup'
import DefaultFields from './DefaultFields'
import defaultVals from './defaultVals'

import { Form, Fields } from '../UI'
const { RT, SubmitBtn } = Fields

const schema = Yup.object({
	name: Yup.string().required('Please enter a name for the page'),
	slug: Yup.string().required('Please enter a unique slug for url').matches(/^\S+$/, {
		message: 'Slug cannot contain spaces (HINT: use a dash instead)',
		excludeEmptyString: true,
	}),
})

const GeneralPageForm = ({ values, onSubmit }) => {
	const initialVals = defaultsDeep(values, {
		...defaultVals,
		rt: '',
	})
	return (
		<Form initialValues={initialVals} onSubmit={onSubmit} validationSchema={schema}>
			{() => (
				<>
					<DefaultFields />
					<RT name='rt' label='Content' />
					<SubmitBtn>Save</SubmitBtn>
				</>
			)}
		</Form>
	)
}
GeneralPageForm.propTypes = {
	values: PropTypes.shape({}),
	onSubmit: PropTypes.func.isRequired,
}
export default GeneralPageForm
