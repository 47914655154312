import React from 'react'
import isString from 'lodash/isString'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Row from './Table.row'
import Block from '../Block'

const Body = styled.tbody``

const TableBody = ({ Err, Empty, colsQty, rowLoaderQty = 2, rows }) => {
	const Error = isString(Err) ? <Block type='err'>{Err}</Block> : isFunction(Err) ? <Err /> : Err
	return (
		<Body>
			{Err ? (
				<Row
					colsQty={colsQty}
					cols={[
						{
							txt: Error,
							colSpan: colsQty,
						},
					]}
				/>
			) : Empty ? (
				<Row
					colsQty={colsQty}
					id='empty'
					cols={[
						{
							txt: Empty,
							colSpan: colsQty,
						},
					]}
				/>
			) : rows ? (
				rows.map((p, i) => <Row key={p.id || `key-${i}`} {...p} colsQty={colsQty} />)
			) : (
				Array(rowLoaderQty)
					.fill()
					.map((_, i) => <Row key={`key-${i}`} colsQty={colsQty} />)
			)}
		</Body>
	)
}
TableBody.propTypes = {
	colsQty: PropTypes.number.isRequired,
	rowLoaderQty: PropTypes.number,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		})
	),
}
export default TableBody
