import { createGlobalStyle } from 'styled-components'
import toastStyles from 'react-toastify/dist/ReactToastify.css'
import fonts from './fonts'
import theme from './theme.config'

const Styles = createGlobalStyle`
	${fonts}
	${toastStyles}
	
	body {
		&.ReactModal__Body--open {
			overflow: hidden;
		}
	}

	button {
	}
	p {
		margin: 0;
		margin-bottom: 1em;
	}
	.overlay {
		background: rgba(255,255,255,0.8);
	}
	.modal-overlay {
		z-index: 100;
		position:fixed;
		&.pic {
			background: ${props => props.theme.colors.black.tint(80)};
		}
		.modal {
			border: solid 2px ${theme.colors.grey};
			background: ${theme.colors.white || '#FFF'};
			border-radius: 4px;
			padding: 1em;
			outline: none;
			max-height: 90vh;
			&.med {
				padding: 2em;
			}
			&.large {
				width: 90vw;
				${props => props.theme.media.tablet} {
					padding: 3em;
				}
			}
			&.pic {
				background: none;
				border: none;
				color: ${props => props.theme.colors.white.val};
				padding: 0;
				width: auto;
				${props => props.theme.media.tablet} {
					padding: 0;
					width: auto;
				}
				.modal-header {
					position: absolute;
					top: 0;
					left: 0;
					.modal-close-btn {
						position: absolute;
						top: 0;
						right: 0;
						transform: translateY(-110%);
						${props => props.theme.media.tablet} {
							transform: translateX(110%);
						}
					}
				}
			}
			.modal-header {
				width: 100%;
				.modal-close-btn {
					margin-left: auto;
					padding: 0.8em 1em;
				}
			}
		}
	}
`

export default Styles
