import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import PulseLoader from 'react-spinners/PulseLoader'
import { useTheme } from '@swfsoft/swf-theme'

const override = css``

const SpinnerWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background: ${props => (props.invert ? props.theme.colors.grey.dark().val : props.theme.colors.white.val)};

	${props => props.theme.css.spinner ? props.theme.css.spinner : ''}
`

const Spinner = ({ type = 'dots', invert, color, ...props }) => {
	const { colors } = useTheme()

	const spinnerProps = {
		color: color || invert ? colors.white.val : colors.grey.val,
		css: override,
	}
	return (
		<SpinnerWrap className='spinner-wrap' invert={invert} {...props}>
			{type === 'dots' ? (
				<PulseLoader {...spinnerProps} />
			) : type === 'circle' ? (
				<ClipLoader {...spinnerProps} />
			) : (
						<p>Loading...</p>
					)}
		</SpinnerWrap>
	)
}
Spinner.propTypes = {
	type: PropTypes.string,
	invert: PropTypes.bool,
	color: PropTypes.string,
}
export default Spinner
