import styled from 'styled-components'

const TableCell = styled.td`
	display: ${props => (props.hideMobile ? 'none' : 'table-cell')};
	font-size: 0.8rem;
	padding-top: 1em;
	padding-bottom: 1em;
	${props => props.theme.media.tablet} {
		display: ${props => (props.hideTab ? 'none' : 'table-cell')};
		font-size: 1rem;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
	${props => props.theme.media.sdesk} {
		display: table-cell;
	}

	a,
	button {
		text-align: center;
	}
`

TableCell.propTypes = {}
export default TableCell
