import React from 'react'
import { Redirect } from '@reach/router'
import get from 'lodash/get'

import { Form, Fields, Icon, Board, useToaster } from '../../../components/UI'

import { useLogin } from '../../../auth'
import { useErrHandler } from '../../../errors'
import { useStoreState } from '../../../store'
import { useState } from '../../../utils'

const { SubmitBtn, Email, Pass } = Fields

const Login = () => {
	const { dashPath } = useStoreState(state => state.user)
	const auth = useLogin()
	const handleErr = useErrHandler({ context: 'login page' })
	const toast = useToaster()

	const [redirect, setRedirect] = useState(dashPath, 'redirect')

	const submitHandler = async vals => {
		try {
			const res = await auth(vals)

			setRedirect(get(res, 'redirect', '/mcp/dash'))
		} catch (err) {
			handleErr({
				err,
			})
			toast('Incorrect username or password', { type: 'err' })
			return Promise.reject(err)
		}
	}
	if (redirect) console.log('Login Page - redirecting to:', redirect)
	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Board titleText='Login'>
			<Form
				initialValues={{
					email: '',
					password: '',
				}}
				onSubmit={async (vals, { setSubmitting }) => {
					try {
						await submitHandler(vals)
					} catch (err) {
						setSubmitting(false)
					}
				}}
			>
				{({ isValid, isSubmitting }) => (
					<>
						<Email name='email' placeholder='Email...' isReq />
						<Pass name='password' placeholder='Password...' isReq />
						<SubmitBtn isInverted isValid={isValid} isSubmitting={isSubmitting}>
							<Icon type='login' />
							Login
						</SubmitBtn>
					</>
				)}
			</Form>
		</Board>
	)
}
Login.propTypes = {}
export default Login
