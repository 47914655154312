import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import {
	ThemeProvider as StylesProvider,
	createGlobalStyles as globalStyles,
} from '@swfsoft/swf-theme'

import { createLibrary } from './Icons/fa.lib'

import IconRaw from './Icons'
import BoardRaw from './Board'
import BlockRaw from './Block'
import CardRaw from './Card'
import TableRaw from './Table'
import FormRaw from './Forms/Form'
import TabsRaw from './Tabs'
import * as Typo from './Typography'
import * as Loaders from './Loaders'

import Themed from './Themed'

import * as swfUtils from './utils'

import * as swfFields from './Forms/FormFields'

import FileUpload from './FileUploader'

import { useToaster as useToast } from './Toaster'

import Mdl from './Modal'

export { default as Border } from './Border'

createLibrary()
/**
 *  SWF THEME WRAPPERS
 * */
export const ThemeProvider = ({ theme, children }) => {
	const color = theme.colors.grey.light().val
	const highlightColor = theme.colors.grey.light('5').val
	const skeletonTheme = {
		color,
		highlightColor,
	}
	return (
		<StylesProvider theme={theme}>
			<SkeletonTheme {...skeletonTheme}>{children}</SkeletonTheme>
		</StylesProvider>
	)
}

export const utils = swfUtils

export const createGlobalStyles = (theme, opts = {}) => globalStyles(theme, opts)

export const Icon = props => <Themed {...props} Component={IconRaw} />

export const Board = props => <Themed {...props} Component={BoardRaw} />

export const Block = props => <Themed {...props} Component={BlockRaw} />

export const Card = props => <Themed {...props} Component={CardRaw} />

export const Table = props => <Themed {...props} Component={TableRaw} />

export const Tabs = props => <Themed {...props} Component={TabsRaw} />

/**
 *  LOADER COMPONENTS
 * */

export const Spinner = props => <Themed {...props} Component={Loaders.Spinner} />

export const ProgressBar = props => <Themed {...props} Component={Loaders.ProgressBar} />

export const SkeletonLoader = props => <Themed {...props} Component={Loaders.Skeleton} />

export const LoaderHOC = Loaders.LoaderHOC

/**
 *  FORM COMPONONENTS
 * */

export const Form = props => <Themed {...props} Component={FormRaw} />

export const Fields = swfFields

/**
 *  TYPOGRAPHICAL COMPONENTS
 * */
export const P = props => <Themed Component={Typo.P} {...props} />
export const EM = props => <Themed Component={Typo.EM} {...props} />
export const Btn = props => <Themed Component={Typo.Btn} {...props} />
export const Strong = props => <Themed Component={Typo.Strong} {...props} />
export const HR = props => <Themed Component={Typo.HR} {...props} />
export const H1 = props => <Themed Component={Typo.H1} {...props} />
export const H2 = props => <Themed Component={Typo.H2} {...props} />
export const H3 = props => <Themed Component={Typo.H3} {...props} />
export const H4 = props => <Themed Component={Typo.H4} {...props} />
export const H5 = props => <Themed Component={Typo.H5} {...props} />
export const H6 = props => <Themed Component={Typo.H6} {...props} />
export const UL = props => <Themed Component={Typo.UL} {...props} />
export const OL = props => <Themed Component={Typo.OL} {...props} />
export const Link = props => <Themed Component={Typo.Link} {...props} />
export const btnStyles = Typo.btnStyles

// Import and install icons for the project
export const buildIconPack = opts => {
	createLibrary(opts)
}

export const FileUploader = FileUpload

export const useToaster = useToast

export const Modal = Mdl
