import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import Block from '../Block'
import { Btn, P } from '../Typography'
import { Spinner } from '../Loaders'
import Icon from '../Icons'
import Modal from '../Modal'

import { useState } from '../utils'

const LibraryWrap = styled.div`
	flex-wrap: wrap;
	.file-item {
		align-items: flex-start;
		margin-bottom: 1em;
		${props => props.theme.media.tablet} {
			margin: 1em;
		}
		p {
			margin: 0;
			max-width: 165px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-bottom: 0.5em;
		}
	}
	.actions {
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-end;
	}
	.img-preview {
		height: 100px;
		width: 100px;
		background: ${props => props.theme.colors.grey.light('6').val};
		align-items: center;
		justify-content: center;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	.delete-btn {
		color: ${props => props.theme.colors.err.val};
	}
	.file-thumb-btn {
		max-width: 100px;
		color: ${props => props.theme.colors.grey.light().val};
		margin-top: 2em;
		&:hover,
		&.selected {
			color: ${props => props.theme.colors.secondary.val};
		}
		.icon {
			height: 50px;
		}
	}
`

const Item = ({ file, isSelected, deleteFileHandler, ...props }) => {
	const [showModal, setShowModal] = useState(false, 'showModal')

	return (
		<div className='file-item'>
			<div className='flex'>
				<div className='img-preview flex'>
					<img src={file.preview.url} alt={file.name} />
				</div>
				<div className='actions flex'>
					<Btn
						className='delete-btn'
						onClick={e => {
							e.preventDefault()
							setShowModal(true)
						}}
					>
						<Icon type='x' />
					</Btn>
					<Btn className={`file-thumb-btn${isSelected ? ' selected' : ''}`} {...props}>
						<Icon type='ok' />
					</Btn>
				</div>
			</div>
			<P className='name'>{get(file, 'name')}</P>
			<Modal
				type='alert'
				isOpen={showModal}
				closeHandler={() => setShowModal(false)}
				confirm={() => deleteFileHandler(file)}
			>
				<P>Are you sure you want to delete this file?</P>
			</Modal>
		</div>
	)
}

const UploadLibrary = ({
	selectedId,
	selectHandler,
	files,
	setFiles,
	setError,
	folder,
	fetchFiles,
	deleteFiles,
}) => {
	const [fetchErr, setFetchErr] = useState(null, 'fetchErr')
	const [isWorking, setWorking] = useState(false, 'isWorking')
	const [currentFolder, setCurrentFolder] = useState(null, 'currentFolder')

	const isFetching = useRef(false)

	useEffect(() => {
		const fetchAllFiles = async () => {
			try {
				setWorking(true)
				isFetching.current = true

				console.log('fetching files')
				const res = await fetchFiles(folder)

				setFiles(res)
				setWorking(false)
				setCurrentFolder(folder)
				isFetching.current = false
			} catch (err) {
				setWorking(false)
				setFetchErr(err.msg)
				isFetching.current = false
			}
		}

		if (!isFetching.current && currentFolder !== folder) fetchAllFiles()
	}, [folder, currentFolder, setFiles, fetchFiles])

	const handleDeleteFile = async file => {
		try {
			await deleteFiles(file.id)

			setFiles(state => state.filter(({ id }) => id !== file.id))
			if (selectedId === file.id) selectHandler(null)
		} catch (err) {
			setError(err.msg)
		}
	}

	return (
		<LibraryWrap className='library flex-tablet'>
			{fetchErr && (
				<Block type='err' usage='overlay'>
					{fetchErr}
				</Block>
			)}
			{isWorking && <Spinner />}
			{!isWorking && isEmpty(files) ? (
				<Block type='info'>{`${folder} folder is empty`}</Block>
			) : null}
			{files &&
				files.map(file => (
					<Item
						file={file}
						key={file.id}
						isSelected={selectedId === file.id}
						onClick={() => selectHandler(file)}
						deleteFileHandler={handleDeleteFile}
					/>
				))}
		</LibraryWrap>
	)
}
UploadLibrary.propTypes = {
	selectHandler: PropTypes.func.isRequired,
	files: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
		})
	),
	fetchFiles: PropTypes.func.isRequired,
	deleteFiles: PropTypes.func.isRequired,
}
export default UploadLibrary
