import { css } from 'styled-components'

import owswaldReg from './Oswald-Regular.ttf'
import owswaldSemi from './Oswald-SemiBold.ttf'
import ptSansBold from './PTSansCaption-Bold.ttf'
import ptSansReg from './PTSansCaption-Regular.ttf'

const fonts = css`
	@font-face {
		font-family: 'Oswald';
		src: url(${owswaldReg}) format('truetype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Oswald';
		src: url(${owswaldSemi}) format('truetype');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'PT Sans Caption';
		src: url(${ptSansReg}) format('truetype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'PT Sans Caption';
		src: url(${ptSansBold}) format('truetype');
		font-weight: 700;
		font-style: normal;
	}
`

export default fonts
