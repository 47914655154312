import React from 'react'
import { useTheme } from '@swfsoft/swf-theme'
import { SkeletonTheme } from 'react-loading-skeleton'
import Skeleton from '../Skeleton'

const Bar = () => {
	const { colors } = useTheme()
	return (
		<SkeletonTheme
			color={colors.loader ? colors.loader.val : colors.aux2.val}
			highlightColor={
				colors.loaderAccent
					? colors.loaderAccent()
					: colors.loader
					? colors.loader.light().val
					: colors.aux2.light().val
			}
		>
			<Skeleton />
		</SkeletonTheme>
	)
}
Bar.propTypes = {}
export default React.memo(Bar)
