import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

const SkeletonWrap = styled.div`
	.react-loading-skeleton {
		border-radius: 0;
	}
`

const SkeletonLoader = props => {
	return (
		<SkeletonWrap className='skeleton-wrap'>
			<Skeleton {...props} />
		</SkeletonWrap>
	)
}
SkeletonLoader.propTypes = {}
export default SkeletonLoader
