import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import * as Yup from 'yup'

import { useState, useToaster, parseBytes } from '../utils'
import Form from '../Forms/Form'
import { Text, Textarea, SubmitBtn } from '../Forms/FormFields'
import Block from '../Block'
import { H5, EM, P, UL } from '../Typography'
import Preview from './FileUploader.preview'

const schema = Yup.object({
	name: Yup.string().required('File must have a name'),
})

const Wrap = styled.div`
	${props => props.theme.media.tablet} {
		flex-direction: column;
		align-items: center;
		transition: transform ${props => props.theme.times.tranS};
		transform: translate3d(${props => (props.hasFile ? '0' : '100%')}, 0, 0);
		/* max-height: 65vh;
		overflow-y: auto;
		padding-right: 1em; */
	}
	${props => props.theme.media.sdesk} {
		/* width: 350px; */
	}
	h5 {
		margin-bottom: 1em;
	}
	.file-stats {
		padding-bottom: 1.5em;
		li {
			display: block;
			padding: 0.5em 0;
			list-style: none;
		}
	}
	.options-preview {
		${props => props.theme.media.tablet} {
			background: ${props => props.theme.colors.grey.light('6').val};
			align-items: center;
			justify-content: center;
			width: 100%;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
`

const FileOptions = ({ file, folder, submitHandler, updateFileHandler }) => {
	const [error, setError] = useState(null, 'error')

	// we need the form to re-render if the file changes
	// so we set a local state for the current file
	const [fileForEdit, setFileForEdit] = useState(file, 'fileForEdit')
	const toast = useToaster()

	useEffect(() => {
		if (fileForEdit && file !== fileForEdit) setFileForEdit(null)
		else if (!fileForEdit && file) setFileForEdit(file)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file, fileForEdit])

	// useEffect(() => {
	// 	if (file && !fileForEdit) setFileForEdit(file)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [fileForEdit])

	const handleSubmit = async fileData => {
		try {
			const res = await updateFileHandler(fileData, folder)
			toast('Success', { type: 'ok' })
			submitHandler(res)
		} catch (err) {
			setError(err.msg)
		}
	}
	return (
		<Wrap className='file-options-content flex-tablet' hasFile={!!file}>
			{error && (
				<Block type='err' usage='overlay' close={() => setError(null)}>
					{error}
				</Block>
			)}
			{fileForEdit && (
				<>
					<Preview file={file} isFull />
					<P className='file-name'>
						<EM>{get(fileForEdit, 'originalFileName')}</EM>
					</P>
					<Form initialValues={fileForEdit} onSubmit={handleSubmit} validationSchema={schema}>
						{({ values, isValid, isSubmitting }) => (
							<>
								<H5>{values.name}</H5>
								<UL className='file-stats'>
									<li>
										<EM>{`Location: ${values.dir}`}</EM>
									</li>
									<li>
										<EM>{`Size: ${parseBytes(values.size)}`}</EM>
									</li>
								</UL>
								<Text name='name' isReq label='Name' />
								<Textarea name='description' label='Description/Caption' />
								<SubmitBtn isValid={isValid} isSubmitting={isSubmitting}>
									Update File Data
								</SubmitBtn>
							</>
						)}
					</Form>
				</>
			)}
		</Wrap>
	)
}
FileOptions.propTypes = {
	submitHandler: PropTypes.func.isRequired,
}
export default FileOptions
