import React from 'react'

const TPALogo = ({ className, ...props }) => (
	<svg
		viewBox='0 0 421 396'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={`site-logo ${className ? className : ''}`}
		{...props}
	>
		<g>
			<path
				d='M325.253 283.567C257.399 306.931 159.602 223.339 155.251 169.569C94.1616 197.597 195.958 314.342 283.575 310.354C299.918 309.61 331.928 297.301 325.253 283.567Z'
				fill='#FFFFFF'
			/>
			<path
				d='M281.042 324.384C214.974 317.243 133.062 273.01 126.155 197.597C87.254 206.365 116.35 320.396 235.961 343.6C257.573 347.792 281.042 337.961 281.042 324.384Z'
				fill='#FFFFFF'
			/>
			<path
				d='M245.402 354.586C190.868 358.212 117.035 315.43 97.0817 236.281C82.5277 258.508 99.9784 332.833 202.202 381.054C209.773 385.391 247.243 362.49 245.402 354.586Z'
				fill='#FFFFFF'
			/>
			<path
				d='M173.069 168.222C174.351 177.699 195.594 202.218 230.132 188.17C377.486 128.232 283.939 -34.8061 222.497 40.6071C273.882 84.3876 234.804 168.849 173.069 168.222Z'
				fill='#FFFFFF'
			/>
			<path
				d='M309.388 10.1518C365 103.44 288.093 210.595 205.838 206.365C207.12 215.843 223.154 238.112 268.681 228.667C413.736 198.574 415.183 -15.9528 309.388 10.1518Z'
				fill='#FFFFFF'
			/>
			<path
				d='M397.732 64.8985C411.55 147.2 347.925 238.929 264.682 243.532C267.418 253.01 294.118 266.555 344.301 251.146C406.458 232.06 448.63 94.489 397.732 64.8985Z'
				fill='#FFFFFF'
			/>
			<path
				d='M57.6219 247.117C82.5747 182.578 133.521 152.422 185.695 152.422C197.424 117.36 78.369 103.461 40.3667 200.75C31.9743 222.235 44.3072 243.281 57.6219 247.117Z'
				fill='#FFFFFF'
			/>
			<path
				d='M21.9964 188.17C67.8582 99.3299 181.332 90.8932 198.878 137.301C241.402 89.9158 85.7997 21.7539 14.1906 132.588C2.68788 150.392 12.2567 190.944 21.9964 188.17Z'
				fill='#FFFFFF'
			/>
			<path
				d='M14.1906 97.3895C82.2526 20.556 201.048 54.7472 214.246 98.8398C235.961 21.3913 89.0717 -51.1214 21.9964 49.1818C6.0523 73.0244 4.91974 97.3895 14.1906 97.3895Z'
				fill='#FFFFFF'
			/>
		</g>
	</svg>
)
TPALogo.propTypes = {}
export default TPALogo
