import React from 'react'
import defaultsDeep from 'lodash/defaultsDeep'
import * as Yup from 'yup'
import { Form, Fields } from '../../../UI'
import CTAFields from '../../CTA.fields'

const { Text, UploadField, Checkbox, Color, SubmitBtn } = Fields

const schema = Yup.object().shape({
	headline: Yup.string().required('Must have a headline'),
	cta: Yup.object().shape({
		icon: Yup.string().required('Must include an icon'),
	}),
	bgImg: Yup.object().required('Please add a background image').nullable(true),
})

const LandingSectionHeroFeaturedItem = ({ submitHandler, values, folder }) => {
	const initialVals = defaultsDeep(values, {
		hide: false,
		headline: '',
		tagline: '',
		cta: {
			hide: false,
			icon: '',
			text: '',
			tagline: '',
			link: '',
		},
		bgColor: '',
		bgImg: null,
	})
	return (
		<Form
			initialValues={initialVals}
			onSubmit={submitHandler}
			className='scrollable'
			validationSchema={schema}
		>
			{() => (
				<>
					<header>
						<legend>Featured Item</legend>
						<SubmitBtn>Save Featured Item</SubmitBtn>
					</header>
					<div className='scroll'>
						<div className='scroll-child'>
							<Checkbox name='hide' label='Hide Featured Item?' default={false} />
							<Text name='headline' label='Headline Text' isReq isPreview />
							<Text name='tagline' label='Tagline (sub text)' />
							<CTAFields />
							<Color
								name='bgColor'
								label='Theme color (HINT: use a color that compliments the background image)'
							/>
							<UploadField name='bgImg' label='Background image' isReq folder={folder} />
						</div>
					</div>
				</>
			)}
		</Form>
	)
}

export default LandingSectionHeroFeaturedItem
