import React from 'react'
import { Fields, H5, Border } from '../UI'

const { Text, Textarea, IconSelect, Checkbox, Select } = Fields

const DisclaimerFields = () => {
	return (
		<Border>
			<H5 mbot>Disclaimer Box</H5>
			<Checkbox name='disclaimer.hide' label='Hide Disclaimer?' />
			<Select
				name='version'
				label='Disclaimer Type'
				options={[{ txt: 'Announcement', val: 'warn' }]}
			/>
			<IconSelect name='disclaimer.icon' label='Disclaimer Icon' />
			<Text name='disclaimer.headline' label='Disclaimer title' />
			<Textarea name='disclaimer.copy' label='Disclaimer text body' />
		</Border>
	)
}
export default DisclaimerFields
