import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../Modal'
import FileUploader from './FileUploader'

import { useState } from '../utils'

const FileUploaderModal = ({ showModal, hideModal, ...props }) => {
	const [isDisabled, setDisabled] = useState(false, 'isDisabled')

	return (
		<Modal
			isOpen={showModal}
			closeHandler={() => !isDisabled && hideModal()}
			canBgClose={false}
			size='large'
		>
			<FileUploader cb={hideModal} disableCB={setDisabled} {...props} />
		</Modal>
	)
}
FileUploaderModal.propTypes = {
	showModal: PropTypes.bool,
	hideModal: PropTypes.func.isRequired,
}
export default FileUploaderModal
