import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import { api } from './api'

export const schemas = {
	get: (id, options = {}) =>
		api(
			'get',
			`/acp/schemas`,
			isPlainObject(id)
				? id
				: {
						params: {
							id: id || get(options, 'id'),
						},
						...options,
				  }
		),

	create: (body, options = {}) =>
		api('post', '/acp/schemas', {
			body,
			...options,
		}),

	update: (id, body, options = {}) =>
		api('put', `/acp/schemas/${id}`, {
			body,
			...options,
		}),
}
