import React from 'react'
import PropTypes from 'prop-types'
import isPlainObject from 'lodash/isPlainObject'
import styled from 'styled-components'

import Loader from './Table.loader.row'

const TH = styled.th`
	&& {
		display: ${props => (props.hideMobile ? 'none' : 'table-cell')};
		${props => props.theme.media.tablet} {
			display: ${props => (props.hideTab ? 'none' : 'table-cell')};
		}
		${props => props.theme.media.sdesk} {
			display: table-cell;
		}
	}
`

const TR = styled.tr``

const TableHeadRow = ({ cols, colsQty, isFooter }) => {
	return (
		<TR>
			{cols ? (
				cols.map((col, i) =>
					isPlainObject(col) ? (
						<TH key={`key-${i}`} {...col}>
							{col.txt}
						</TH>
					) : (
							<TH key={`key-${i}`}>{col}</TH>
						)
				)
			) : (
					<Loader qty={colsQty} Cell={TH} />
				)}
		</TR>
	)
}
TableHeadRow.propTypes = {
	cols: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
	colsQty: PropTypes.number.isRequired,
}
export default TableHeadRow
