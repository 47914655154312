import React, { useEffect } from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { Board, EM, P, Link, SkeletonLoader, Block, UL } from '../../../components/UI'
import { useAsync } from '../../../utils'
import { mcp } from '../../../api'

const MCPPagesView = styled.div`
	.add-page-link {
		display: none;
	}
`

const MCPPages = () => {
	const { res, isWorking, err, cancel } = useAsync(() => {
		return mcp.pages.get()
	})

	useEffect(() => {
		return () => cancel()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Board titleText='Pages'>
			<MCPPagesView>
				{isWorking ? (
					<UL isPlain>
						{Array(3)
							.fill('')
							.map((_, idx) => (
								<li key={idx}>
									<SkeletonLoader />
								</li>
							))}
					</UL>
				) : err ? (
					<Block type='err'>{err.message}</Block>
				) : isEmpty(res) ? (
					<P>
						<EM>No pages found...</EM>
					</P>
				) : (
					<>
						<UL isPlain>
							{res.map(({ id, name }) => (
								<li key={id}>
									<Link to={`/mcp/page?id=${id}`}>{name}</Link>
								</li>
							))}
						</UL>
					</>
				)}
				<Link className='add-page-link' to='/mcp/page'>
					Create New Page
				</Link>
			</MCPPagesView>
		</Board>
	)
}
export default MCPPages
