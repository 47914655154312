import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import { useState, parseQueryParams, useToaster } from '../../../utils'
import { Board, SkeletonLoader, Spinner, Block } from '../../../components/UI'
import { mcp } from '../../../api'
import SponsorForm from '../../../components/Sponsors/Sponsor.form'

// const Form = ({ pageID, slug, ...props }) =>
// 	pageID === '5fa9ddce02684e838303b673' || slug === 'home' ? (
// 		<Home {...props} />
// 	) : (
// 		<Gen {...props} />
// 	)

const MCPEditSponsor = ({ location }) => {
	const { id, slug } = parseQueryParams(location)
	const toast = useToaster()

	const [isLoading, setLoading] = useState(id || slug, 'isLoading')

	const [sponsorData, setSponsorData] = useState({}, 'sponsorData')

	const [error, setError] = useState(null, 'error')

	const pageName = sponsorData.orgName

	useEffect(() => {
		const fetchSponsor = async () => {
			setLoading(true)
			try {
				const sponsor = await mcp.sponsors.get({ id, slug })
				if (sponsor) setSponsorData(sponsor)
				setLoading(false)
			} catch (err) {
				console.error(err)
				setError(err.message)
				setLoading(false)
			}
		}
		if (id || slug) fetchSponsor()
	}, [id, slug])

	const handleSubmit = async (vals, opts) => {
		try {
			let result
			if (vals.id) result = await mcp.sponsors.update(vals)
			else result = await mcp.sponsors.create(vals)

			if (!id) navigate(`/mcp/sponsors?id=${result.id}`)
			else toast('Sponsor saved!', { type: 'ok' })
		} catch (err) {
			setError(err.message)
		}
	}

	return (
		<Board titleText={isLoading ? <Spinner /> : pageName || 'New Sponsor'}>
			{isLoading ? (
				<>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</>
			) : error ? (
				<Block type='err'>{error}</Block>
			) : (
				<SponsorForm data={sponsorData} handler={handleSubmit} />
			)}
		</Board>
	)
}
MCPEditSponsor.propTypes = {
	id: PropTypes.string,
}
export default MCPEditSponsor
