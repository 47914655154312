import React from 'react'
import map from 'lodash/map'

import { Fields } from '../../../UI'
import config from '../../../../styles/theme.config'

const { RT } = Fields

const LandingSectionGenFields = () => {
	return (
		<>
			<RT name='content' label='Content' colors={map(config.colors, val => val)} />
		</>
	)
}
LandingSectionGenFields.propTypes = {}
export default LandingSectionGenFields
