import React from 'react'
import Auth from '@aws-amplify/auth'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ToastContainer } from 'react-toastify'

import { createTheme } from '@swfsoft/swf-theme'
import { createGlobalStyles, ThemeProvider } from './components/UI'
import themeConfig from './styles/theme.config'
import ProjStyles from './styles'
import Routes from './Routing'
import { model, createStore, StoreProvider } from './store'

const env = process.env.REACT_APP_ENV

const toasterSettings = {
	position: 'bottom-left',
	autoClose: 6000,
	closeOnClick: true,
	pauseOnHover: true,
}

// INSTANTIATE THEME
const theme = createTheme(themeConfig)

const GlobalStyles = createGlobalStyles(theme)

// INITIALIZE ERROR TRACKING
// https://docs.bugsnag.com/platforms/javascript/react/

const bugsnagClient = Bugsnag.start({
	apiKey: '6c329a649730965806e4e3a12e4e2eeb',
	plugins: [new BugsnagPluginReact()],
	onError: event => {
		// Disable for dev
		if (env === 'dev' || env === 'staging') {
			console.log(
				`%c BUGSNAG - report that would have sent:`,
				`color: ${theme.colors.white.val}; background-color: ${theme.colors.err.val}; padding: 4px;`,
				event
			)
			return false
		}

		// NOTE: You can mutate error report sent to bugsnag
		// see: https://docs.bugsnag.com/platforms/react-native/expo/customizing-error-reports/
	},
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// GLOBAL STORE
const storeModel = {
	errTrackClient: bugsnagClient,
	...model,
}

const store = createStore(storeModel)

// INITIAlIZE AUTHENTICATION
Auth.configure({
	mandatorySignIn: true,
	authenticationFlowType: 'USER_SRP_AUTH',
	userPoolId: 'us-west-2_gAQcsIKHv',
	userPoolWebClientId: '4klneg6htaum5ab1p3m9m3onpq',
	region: 'us-west-2',
})

const App = () => {
	return (
		<ErrorBoundary>
			<StoreProvider store={store}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<ProjStyles />
					<Routes />
					<ToastContainer {...toasterSettings} />
				</ThemeProvider>
			</StoreProvider>
		</ErrorBoundary>
	)
}

export default App
