import React from 'react'
import styled from 'styled-components'
import { Board, Link } from '../../../components/UI'

const MCPDashView = styled.div`
	li {
		list-style: none;
		margin: 1em 0;
	}
`

const MCPDash = () => {
	return (
		<MCPDashView>
			<Board titleText='MCP Dash'>
				<nav>
					<li>
						<Link to='/mcp/pages'>Pages</Link>
					</li>
					<li>
						<Link to='/mcp/events'>Events</Link>
					</li>
					{/* <li>
						<Link to='/mcp/sponsors'>Sponsors</Link>
					</li> */}
				</nav>
			</Board>
		</MCPDashView>
	)
}
MCPDash.propTypes = {}
export default MCPDash
