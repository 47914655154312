import React from 'react'
import styled from 'styled-components'
import LoggedInNav from './Nav.loggedin'
import MCPNav from './Nav.MCP'
import { useStoreState, useStoreActions } from '../../store'

const NavWrap = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	background: ${props => props.theme.colors.black.tint()};
	width: 100vw;
	height: 100vh;
	z-index: 100;
	.inner-wrap {
		overflow-y: auto;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 80%;
		background: ${props => props.theme.colors.white.val};
		padding: 10vh 0;
		${props => props.theme.media.tablet} {
			width: 50%;
		}
		${props => props.theme.media.sdesk} {
			width: 300px;
		}
		li {
			list-style: none;
			a {
				padding: 1em 2em;
				display: block;
			}
		}
	}
`

const MainNav = () => {
	const { isNavOpen } = useStoreState(state => state.view)
	const { toggleNav } = useStoreActions(actions => actions.view)

	return (
		isNavOpen && (
			<NavWrap onClick={() => toggleNav()}>
				<div className='inner-wrap'>
					<MCPNav className='nav' />
					<LoggedInNav className='nav' />
				</div>
			</NavWrap>
		)
	)
}
MainNav.propTypes = {}
export default MainNav
