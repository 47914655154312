import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useField } from 'formik'
import { ChromePicker } from 'react-color'

import { Btn } from '../../Typography'

import { useState } from '../../utils'

const BG = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
`
const ColorPickerWrap = styled.div`
	.color-preview {
		background: ${props => props.color};
		height: 2.5em;
		width: 2.5em;
		display: block;
		border: solid 1px ${props => props.theme.colors.grey.val};
		${props => props.theme.media.tablet} {
			height: 3em;
			width: 3em;
		}
	}
	input {
		/* display: none; */
	}
	.chrome-picker {
		z-index: 2;
		position: absolute;
	}
`

const ColorPickerField = props => {
	// eslint-disable-next-line no-unused-vars
	const [field, _, helpers] = useField(props)

	const [color, setColor] = useState(field.value, 'color')
	const [open, setOpen] = useState(false, 'open')

	const closePicker = () => {
		helpers.setValue(color)
		setOpen(false)
	}

	return (
		<>
			{open && <BG className='bg' onClick={() => closePicker()} />}
			<ColorPickerWrap style={{ backgroundColor: color }}>
				<Btn
					className='color-preview'
					onClick={e => {
						e.preventDefault()
						setOpen(true)
					}}
				/>
				{open && (
					<ChromePicker
						onChangeComplete={({ hex, ...e }) => setColor(hex)}
						disableAlpha={true}
						color={color}
						defaultView='hex'
					/>
				)}
			</ColorPickerWrap>
		</>
	)
}
ColorPickerField.propTypes = {
	name: PropTypes.string.isRequired,
}
export default React.memo(ColorPickerField)
