import replace from 'lodash/replace'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export { default as useState } from './useState'
export { default as useAsync } from './useAsync'
export { default as useProtect } from './useProtect'

export { default as useToaster } from '../components/UI/Toaster/useToaster'

export const parseQueryParams = search => {
	const s = isString(search) ? search : get(search, 'search') // might be the location object

	const queryString = replace(s, '?', '')

	if (queryString.length)
		return queryString.split('&').reduce((obj, curr) => {
			const [query, param] = curr.split('=')
			// eslint-disable-next-line no-param-reassign
			obj[query] = param
			return obj
		}, {})

	return {}
}

export const isExternalLink = link => (link ? link.substring(0, 4) === 'http' : false)

export const parseBytes = (bytes, decimals = 2) => {
	if (bytes === 0 || !isNumber(bytes)) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const stateList = [
	{ full: 'Alabama', abrev: 'AL' },
	{ full: 'Alaska', abrev: 'AK' },
	{ full: 'Arizona', abrev: 'AZ' },
	{ full: 'Arkansas', abrev: 'AR' },
	{ full: 'California', abrev: 'CA' },
	{ full: 'Colorado', abrev: 'CO' },
	{ full: 'Connecticut', abrev: 'CT' },
	{ full: 'Delaware', abrev: 'DE' },
	{ full: 'Florida', abrev: 'FL' },
	{ full: 'Georgia', abrev: 'GA' },
	{ full: 'Hawaii', abrev: 'HI' },
	{ full: 'Idaho', abrev: 'ID' },
	{ full: 'Illinois', abrev: 'IL' },
	{ full: 'Indiana', abrev: 'IN' },
	{ full: 'Iowa', abrev: 'IA' },
	{ full: 'Kansas', abrev: 'KS' },
	{ full: 'Kentucky', abrev: 'KY' },
	{ full: 'Louisiana', abrev: 'LA' },
	{ full: 'Maine', abrev: 'ME' },
	{ full: 'Maryland', abrev: 'MD' },
	{ full: 'Massachusetts', abrev: 'MA' },
	{ full: 'Michigan', abrev: 'MI' },
	{ full: 'Minnesota', abrev: 'MN' },
	{ full: 'Mississippi', abrev: 'MS' },
	{ full: 'Missouri', abrev: 'MO' },
	{ full: 'Montana', abrev: 'MT' },
	{ full: 'Nebraska', abrev: 'NE' },
	{ full: 'Nevada', abrev: 'NV' },
	{ full: 'New Hampshire', abrev: 'NH' },
	{ full: 'New Jersey', abrev: 'NJ' },
	{ full: 'New Mexico', abrev: 'NM' },
	{ full: 'New York', abrev: 'NY' },
	{ full: 'North Carolina', abrev: 'NC' },
	{ full: 'North Dakota', abrev: 'ND' },
	{ full: 'Ohio', abrev: 'OH' },
	{ full: 'Oklahoma', abrev: 'OK' },
	{ full: 'Oregon', abrev: 'OR' },
	{ full: 'Pennsylvania', abrev: 'PA' },
	{ full: 'Rhode Island', abrev: 'RI' },
	{ full: 'South Carolina', abrev: 'SC' },
	{ full: 'South Dakota', abrev: 'SD' },
	{ full: 'Tennessee', abrev: 'TN' },
	{ full: 'Texas', abrev: 'TX' },
	{ full: 'Utah', abrev: 'UT' },
	{ full: 'Vermont', abrev: 'VT' },
	{ full: 'Virginia', abrev: 'VA' },
	{ full: 'Washington', abrev: 'WA' },
	{ full: 'West Virginia', abrev: 'WV' },
	{ full: 'Wisconsin', abrev: 'WI' },
	{ full: 'Wyoming', abrev: 'WY' },
]
