import React from 'react'
import map from 'lodash/map'
import { Fields } from '../../../UI'
import config from '../../../../styles/theme.config'

const { UploadField, RT, ArrayFields, Checkbox, Text } = Fields

const LandingSectionSponsorFields = () => {
	return (
		<>
			<RT
				name='content'
				label='Content (Above Team list)'
				colors={map(config.colors, val => val)}
			/>
			<ArrayFields name='sponsors' label='Sponsor' addText='Add sponsor'>
				<Checkbox name='hide' label='Hide sponsor?' default={false} />
				<Text name='name' label='Name' isReq isPreview />
				<Text name='link' label='Link' />
				<UploadField name='logo' label='Logo' isReq />
			</ArrayFields>
		</>
	)
}
LandingSectionSponsorFields.propTypes = {}
export default LandingSectionSponsorFields
