import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { useField } from 'formik'

import { Btn } from '../../../Typography'
import Label from '../Label'
import Icon from '../../../Icons'
import Block from '../../../Block'

import { UploaderModal } from '../../../FileUploader'
import Preview from '../../../FileUploader/FileUploader.preview'

import { mcp } from '../../../../../api'

import { useState } from '../../../utils'

const UploadFieldWrap = styled.div`
	margin: 1em 0;
	.remove-file-btn {
		color: ${props => props.theme.colors.err.val};
		margin-left: 0.5em;
		padding: 0.5em;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			margin: 0;
		}
	}
	.upload-btn {
		padding: 1em;
	}
	.upload-input {
		/* justify-content: center; */
		align-items: flex-start;
		margin: 1em 0;
	}
	.preview {
		max-width: 100px;
		margin-top: 0.5em;
		img {
			width: 100%;
			height: auto;
		}
	}
`

const UploadField = ({
	label,
	isReq,
	children,
	folder,
	allowedFolders,
	api = mcp.files,
	...props
}) => {
	const [field, { error, touched }, helpers] = useField(props)
	const [file, setFile] = useState(get(field, 'value', null), 'file')
	const [uploadErr, setUploadErr] = useState(null, 'uploadErr')
	const [openUploader, setOpenUploader] = useState(false, 'openUploader')

	const afterUpload = res => {
		// console.log('Upload Field | upload response: ', res)
		setOpenUploader(false)
		if (!res) return
		setFile(res)
		if (helpers.setValue) helpers.setValue(res)
		else if (props.onChange) props.onChange(res)

		if (uploadErr) setUploadErr(null)
	}

	const openUploaderHandler = e => {
		e.preventDefault()
		setOpenUploader(true)
	}

	const removeHandler = e => {
		e.preventDefault()
		if (helpers.setValue) helpers.setValue(null)
		setFile(null)
		if (isReq) setUploadErr(isString(isReq) ? isReq : 'Image field is required')
	}

	return (
		<UploadFieldWrap {...props}>
			{label && <Label isReq={isReq}>{label}</Label>}
			<div className='upload-input flex'>
				{!isEmpty(file) && <Preview file={file} />}
				{!isEmpty(file) ? (
					<Btn className='remove-file-btn' onClick={removeHandler} title='Remove file'>
						<Icon type='x' />
					</Btn>
				) : (
					<Btn className='upload-btn' onClick={openUploaderHandler} title='Upload/select file'>
						<Icon type='fileUpload' />
						{children ? children : 'Upload file...'}
					</Btn>
				)}
			</div>
			{(uploadErr || (touched && error)) && (
				<Block type='err'>{uploadErr || isString(error) ? error : 'Image is required'}</Block>
			)}
			<UploaderModal
				showModal={openUploader}
				hideModal={afterUpload}
				folder={folder}
				allowedFolders={allowedFolders}
				api={api}
			/>
		</UploadFieldWrap>
	)
}
UploadField.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.shape({
		name: PropTypes.string,
		preview: PropTypes.shape({
			url: PropTypes.string,
		}),
	}),
}
export default React.memo(UploadField)
