import React from 'react'
import { Router, Redirect } from '@reach/router'

import Layout from '../layouts/main.layout'

/**
 *  IMPORT PAGES
 * */

// AUTH PAGES
import Login from '../pages/Auth/Login'
import Logout from '../pages/Auth/Logout'
import CreatePass from '../pages/Auth/CreatePass'
import ForgotPass from '../pages/Auth/ForgotPass'
import ResetPass from '../pages/Auth/ResetPass'

import MCP from './MCP.routes'
// import Org from '../pages/Org'
// import Volunteer from '../pages/Volunteer'
// import User from '../pages/User'

// import ACP from '../pages/ACP'
import Page404 from '../pages/404'

const Routing = () => {
	return (
		<Router>
			<Layout default>
				<MCP path='mcp/*' />

				{/* <Org />
			<Volunteer />
			<User /> */}

				<Redirect from='/' to='login' noThrow />
				<Login path='login' />
				<Logout path='logout' />
				<CreatePass path='create-pass' />
				<ForgotPass path='forgot-pass' />
				<ResetPass path='reset-pass' />

				{/* <ACP /> */}
				<Page404 default />
			</Layout>
		</Router>
	)
}
Routing.propTypes = {}
export default Routing
