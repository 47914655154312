import React from 'react'
import styled from 'styled-components'
import defaultsDeep from 'lodash/defaultsDeep'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { mcp } from '../../../../api'
import { Fields, Form, HR } from '../../../../components/UI'
import Gallery from '../MCP.Event.Sections.js/MCP.Event.section.Pics'
import { H6 } from '../../../../components/UI/Typography'

const EventProfilesFormView = styled.div`
	.field-group {
		padding: 1em;
		border: solid 1px ${props => props.theme.colors.grey.val};
		margin-bottom: 1em;
	}
`

const { Checkbox, Text, Textarea, UploadField, ArrayFields, Select, RT, SubmitBtn, IconSelect } =
	Fields

const typeOptions = [
	{
		txt: 'Farm',
		val: 'farm',
		icon: 'farm',
	},
	{
		txt: 'Artisan',
		val: 'artisan',
		icon: 'artist',
	},
	{
		txt: 'Performer',
		val: 'performer',
		icon: '',
	},
	{
		txt: 'Food & Beverage',
		val: 'food',
	},
]

const defaultVals = {
	hide: false,
	name: '',
	label: {
		txt: '',
		icon: '',
		description: '',
	},
	description: '',
	metaDescription: '',
	type: '',
	pic: {},
	banner: {},
	website: '',
	socs: [],
	about: '',
	slug: '',
	pics: [],
}

const schema = Yup.object({
	name: Yup.string().required('Please enter a name'),
	slug: Yup.string().required('Please enter a url friendly unique name'),
	description: Yup.string().required('Please enter a description'),
	type: Yup.string().required('Please select a type'),
	banner: Yup.object().required('Please upload/select a banner photo'),
})

const EventProfilesForm = ({ submitHandler, folderName, values }) => {
	const initialVals = defaultsDeep(values, defaultVals)
	return (
		<EventProfilesFormView>
			<Form
				initialValues={initialVals}
				onSubmit={vals => submitHandler(vals)}
				validationSchema={schema}
				className='scrollable'
			>
				{() => (
					<>
						<header>
							<legend>Profile</legend>
							<SubmitBtn>Save Profile</SubmitBtn>
						</header>
						<div className='scroll'>
							<div className='scroll-child'>
								<Checkbox name='hide' label='Hide? (will not show up on website)' />
								<Select
									name='type'
									label='Profile Type'
									isReq
									options={typeOptions}
									defaultTxt='Select Type...'
								/>
								<div className='field-group'>
									<H6>Label</H6>
									<Text name='label.txt' label='Label Text (max chars. 15)' />
									<IconSelect name='label.icon' label='Label Icon' />
									<Textarea
										name='label.description'
										label='Label Description (will show up when hovering over the label with a mouse)'
									/>
								</div>
								<Text name='name' label='Name' isPreview isReq />
								<Text
									name='slug'
									label='Slug (URL path) NOTE: This should not change after the profile is first published.'
									isReq
								/>
								<Text name='website' label='Website URL' />
								<Textarea name='description' label='Intro text (1 paragraph)' isReq />
								<Textarea name='metaDescription' label='SEO Description (1 paragraph)' />
								<UploadField
									name='pic'
									default={{}}
									label='Main (Circular) Photo'
									isReq='Please upload/select an image'
									folder={folderName}
									allowedFolders={[folderName]}
									api={mcp.files}
								/>
								<UploadField
									name='banner'
									label='Banner (Background) Photo'
									default={{}}
									isReq='Please upload/select an image'
									folder={folderName}
									allowedFolders={[folderName]}
									api={mcp.files}
								/>
								<HR />
								<ArrayFields name='socs' addText='Add Social Media Accounts' default={[]}>
									<Checkbox name='hide' label='Hide?' />
									<IconSelect
										name='icon'
										label='Icon'
										isPreview
										previewType='icon'
										isReq
										allow='socs'
									/>
									<Text name='to' label='URL' isPreview />
								</ArrayFields>
								<HR />
								<RT name='about' label='Profile Content' />
								<HR />
								<Gallery folder={folderName} />
							</div>
						</div>
					</>
				)}
			</Form>
		</EventProfilesFormView>
	)
}
EventProfilesForm.propTypes = {
	folderName: PropTypes.string.isRequired,
	submitHandler: PropTypes.func.isRequired,
}
export default EventProfilesForm
