import values from 'lodash/values'
import get from 'lodash/get'

import * as light from '@fortawesome/pro-light-svg-icons'

import {
	faQuestionCircle as farQuestionCircle,
	faHeadSideMask as farHeadSideMask,
	faBedAlt as farBedAlt,
} from '@fortawesome/pro-regular-svg-icons'

import {
	faCaretUp as fasCaretUp,
	faCaretDown as fasCaretDown,
	faInfo as fasInfo,
} from '@fortawesome/pro-solid-svg-icons'

// import { } from '@fortawesome/pro-duotone-svg-icons'

import {
	faFacebookSquare as fabFacebookSquare,
	faInstagramSquare as fabInstagramSquare,
	faTwitterSquare as fabTwitterSquare,
	faYoutubeSquare as fabYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons'

export const iconLib = [
	{
		name: 'Alerts',
		group: 'alerts',
		icons: {
			err: ['fal', 'exclamation-circle'],
			ok: ['fal', 'check-circle'],
			warn: ['fal', 'exclamation'],
			info: ['fal', 'info-circle'],
			helper: ['fas', 'info'],
			question: ['far', 'question-circle'],
			alert: ['fal', 'exclamation-triangle'],
		},
	},
	{
		name: 'Directional',
		group: 'dir',
		icons: {
			arrowRight: ['fal', 'arrow-right'],
			arrowLeft: ['fal', 'arrow-left'],
			arrowDown: ['fal', 'arrow-down'],
			arrowUp: ['fal', 'arrow-up'],
			chevronRight: ['fal', 'chevron-right'],
			chevronLeft: ['fal', 'chevron-left'],
			chevronUp: ['fal', 'chevron-up'],
			chevronDown: ['fal', 'chevron-down'],
			caretDown: ['fas', 'caret-down'],
			caretUp: ['fas', 'caret-up'],
		},
	},
	{
		name: 'Navigation',
		group: 'nav',
		icons: {
			x: ['fal', 'times'],
			hamburger: ['fal', 'bars'],
			circlesV: ['fal', 'ellipsis-v-alt'],
			circlesH: ['fal', 'ellipsis-h-alt'],
			dotsV: ['fal', 'ellipsis-v'],
			dotsH: ['fal', 'ellipsis-h'],
			plus: ['fal', 'plus'],
			login: ['fal', 'sign-in-alt'],
			logout: ['fal', 'sign-out-alt'],
		},
	},
	{
		name: 'Social Media',
		group: 'socs',
		icons: {
			fb: ['fab', 'facebook-square'],
			ig: ['fab', 'instagram-square'],
			tw: ['fab', 'twitter-square'],
			yt: ['fab', 'youtube-square'],
		},
	},
	{
		name: 'People',
		group: 'user',
		icons: {
			user: ['fal', 'user-circle'],
			users: ['fal', 'users'],
			userEdit: ['fal', 'user-edit'],
			userAdd: ['fal', 'user-plus'],
		},
	},
	{
		name: 'Utility',
		group: 'utility',
		icons: {
			hero: ['fal', 'plus-hexagon'],
			events: ['fal', 'calendar-star'],
			mapMarker: ['fal', 'map-marker-alt'],
			film: ['fal', 'film'],
			flag: ['fal', 'flag'],
			camcorder: ['fal', 'camcorder'],
			pages: ['fal', 'file-alt'],
			clock: ['fal', 'clock'],
			plane: ['fal', 'paper-plane'],
			bed: ['far', 'bed-alt'],
			image: ['fal', 'image'],
			fileUpload: ['fal', 'file-upload'],
			info: ['fal', 'info-circle'],
			folder: ['fal', 'folder-open'],
			utensils: ['fal', 'utensils-alt'],
			question: ['far', 'question-circle'],
			alert: ['fal', 'exclamation-triangle'],
			email: ['fal', 'envelope'],
			phone: ['fal', 'phone-alt'],
			lockAlt: ['fal', 'lock-alt'],
			folderTree: ['fal', 'folder-tree'],
			booth: ['fal', 'store'],
		},
	},
	{
		name: 'Misc.',
		group: 'misc',
		icons: {
			medMask: ['far', 'head-side-mask'],
			form: ['fal', 'clipboard-list-check'],
			swf: ['fal', 'hammer-war'],
			mountain: ['fal', 'mountain'],
			tulip: ['fal', 'flower-tulip'],
			personSign: ['fal', 'person-sign'],
			handShake: ['fal', 'hands-helping'],
			handShakePro: ['fal', 'handshake-alt'],
			fistPump: ['fal', 'fist-raised'],
			handsHeart: ['fal', 'hands-heart'],
			heartCircle: ['fal', 'heart-circle'],
			leafHeart: ['fal', 'leaf-heart'],
			gift: ['fal', 'gift'],
			rules: ['fal', 'whistle'],
			eye: ['fal', 'eye'],
			eyeClosed: ['fal', 'eye-slash'],
			farm: ['fal', 'farm'],
			corn: ['fal', 'corn'],
			tentacity: ['fal', 'cloud-moon'],
			stars: ['fal', 'stars'],
			star: ['fal', 'star'],
			video: ['fal', 'video'],
		},
	},
	{
		name: 'Sponsors',
		group: 'sponsors',
		icons: {
			seedling: ['fal', 'seedling'],
			cannabis: ['fal', 'cannabis'],
			wheat: ['fal', 'wheat'],
			oakLeaf: ['fal', 'leaf-oak'],
		},
	},
]

export const defaultMap = iconLib.reduce(
	(obj, group) => ({
		...obj,
		...group.icons,
	}),
	{}
)

const lightIcons = values(light).filter(val => !!get(val, 'icon'))

export const defaultLib = [
	...lightIcons,
	farHeadSideMask,
	farQuestionCircle,
	farBedAlt,
	fasCaretDown,
	fasCaretUp,
	fasInfo,
	fabFacebookSquare,
	fabInstagramSquare,
	fabTwitterSquare,
	fabYoutubeSquare,
]
