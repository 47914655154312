import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { createTheme, useTheme } from '@swfsoft/swf-theme'

import Label from './Label'
import Block from '../../Block'
import Icon from '../../Icons'

const InputWrap = ({ children, suffix, touched, error, showErr, icon, ...rest }) => (
	<div className='input-wrap'>
		<div className='input-inner-wrap'>
			{icon && <Icon type={icon} className='placeholder-icon' />}
			{children}
			{suffix && <span className='input-suffix'>{suffix}</span>}
		</div>
		{touched && error && showErr && <Block type='err'>{error}</Block>}
	</div>
)

const MultiWrap = styled.div`
	border: solid 1px;
	border-color: ${props =>
		props.isActive ? props.theme.colors.secondary.val : props.theme.colors.grey.val};
	display: ${props => (props.isHoriz ? 'flex' : 'block')};
	position: relative;
	&& input {
		border: none;
	}
`

const Wrap = styled.div`
	display: ${props => (props.labelPos === 'left' || props.labelPos === 'right' ? 'flex' : 'block')};
	margin-bottom: ${props =>
		props.labelPos === 'left' || props.labelPos === 'right' ? '1em' : '1em'};
	background: ${props => props.theme.colors.white.val};
	position: relative;
	align-items: center;

	input,
	textarea,
	select {
		display: block;
		outline: none;
		box-shadow: none;
		background: none;
		width: 100%;
		border-radius: 0;
		/* border-top-right-radius: 8px; */
		border: solid 1px;
		padding: 0.5em 1em;
		padding-left: ${props => (props.hasIcon ? '2em' : '1em')};
		font-size: 15px;
		border-color: ${props =>
			props.status === 'err'
				? props.theme.colors.err.val
				: props.status === 'ok'
				? props.theme.colors.ok.val
				: props.theme.colors.grey.val};
		&:hover {
			cursor: pointer;
			border-color: ${props => props.theme.colors.secondary.val};
		}
		&:focus {
			outline: none;
			border-color: ${props => props.theme.colors.secondary.val};
		}
		&:disabled {
			border-color: ${props => props.theme.colors.disabled.val};
			color: ${props => props.theme.colors.disabled.val};
			${props => props.theme.media.sdesk} {
				&:hover {
					cursor: default;
				}
			}
		}
	}
	input, textarea {
		&:read-only {
			border-color: ${props => props.theme.colors.disabled.val};
			color: ${props => props.theme.colors.disabled.val};
			${props => props.theme.media.sdesk} {
				&:hover {
					cursor: default;
				}
			}
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	input,
	&.select-field {
		max-width: 100%;
		${props => props.theme.media.tablet} {
			max-width: 400px;
		}
	}

	&.checkbox-field, &.radio-field {
		label {
			${props => props.theme.media.sdesk}  {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	input[type='checkbox'] {
		border: none;
		padding: 0;
		width: auto;
	}

	input[type='radio'] {
		margin: 0;
		width: 1em;
		height: 1em;
	}

	textarea {
		border-bottom-right-radius: 0;
		width: 100%;
		max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
		margin: 0 !important;
	}

	.input-wrap {
		position: relative;
		.input-inner-wrap {
			position: relative;
			display: ${props => (props.hasSuffix ? 'flex' : 'block')};
			align-items: center;
		}
		.placeholder-icon {
			color: ${props => props.theme.colors.grey.light('4').val};
			position: absolute;
			left: 0.5em;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			z-index: 1;
			display: ${props => (props.hasIcon ? 'inline-block' : 'none')};
		}
		.input-suffix {
			white-space: nowrap;
			margin-left: 0.5em;
		}

		input {
			&::placeholder {
				color: ${props => props.theme.colors.grey.light('4').val};
				/* padding-left: ${props => (props.hasIcon ? '1em' : '0.5em')}; */
			}
		}
		.block-alert {
			font-size: 0.8em;
		}
	}
	.label-wrap {
		padding-left: ${props => (props.labelPos === 'right' ? '1em' : 'initial')};
		padding-right: ${props => (props.labelPos === 'left' ? '1em' : 'initial')};
		padding-bottom: ${props => (props.labelPos === 'top' ? '0.5em' : 'initial')};
		padding-top: ${props => (props.labelPos === 'bottom' ? '0.5em' : 'initial')};
	}
	.warn,
	.err {
		padding: 0;
		padding-top: 0.5em;
		${props =>
			props.labelPos === 'right' &&
			`
			min-width: 100%;
		`}
	}

	.rc-color-picker-wrap {
		span {
			display:block;
			height: 1em;
			width: 1em;
			border: solid 1px ${props => props.theme.colors.grey.val};
		}
	}

	${props => (props.theme.css.field ? props.theme.css.field : '')}
`

const FieldWrap = ({
	status,
	labelPosition = 'top',
	label,
	inputName,
	inputId,
	isReq,
	multiHoriz,
	error,
	touched,
	icon,
	className,
	isMulti,
	isActive,
	renderWrap = true,
	type,
	show = true,
	children,
	suffix,
	helperText,
}) => {
	const theme = useTheme()
	const swfTheme = theme ? null : createTheme({ context: 'SwF-UI - FieldWrap.js' })
	// console.log(`${inputName} | touched: ${touched} | error: ${error}`)
	const styles = show === false ? { display: 'none' } : null
	const hasIcon = !!icon
	return renderWrap ? (
		<Wrap
			status={status}
			className={className ? `field-wrap ${className} ${type}-field` : `field-wrap ${type}-field`}
			hasIcon={hasIcon}
			labelPos={labelPosition}
			hasSuffix={!!suffix}
			isMulti={isMulti}
			style={styles}
			theme={theme || swfTheme}
		>
			{label && (labelPosition === 'left' || labelPosition === 'top') && (
				<div className='label-wrap'>
					<Label pos={labelPosition} htmlFor={inputId || inputName} isReq={isReq}>
						{label}
					</Label>
					{labelPosition === 'left' && helperText && typeof helperText === 'string' && (
						<Block type='helper' className='helper-text' pad='8px 0'>
							{helperText}
						</Block>
					)}
				</div>
			)}
			{labelPosition === 'bottom' && helperText && typeof helperText === 'string' && (
				<Block type='helper' className='helper-text' pad='8px 0'>
					{helperText}
				</Block>
			)}
			{isMulti ? (
				<MultiWrap isHoriz={multiHoriz} isActive={isActive}>
					{children}
				</MultiWrap>
			) : (
				<InputWrap
					error={error}
					touched={touched}
					helperText={helperText}
					suffix={suffix}
					showErr={!label || (labelPosition !== 'right' && labelPosition !== 'bottom')}
					icon={icon}
				>
					{children}
				</InputWrap>
			)}
			{labelPosition === 'top' && helperText && typeof helperText === 'string' && (
				<Block type='helper' className='helper-text' pad='8px 0'>
					{helperText}
				</Block>
			)}
			{label && (labelPosition === 'right' || labelPosition === 'bottom') && (
				<>
					<div className='label-wrap'>
						<Label pos={labelPosition} htmlFor={inputId || inputName} isReq={isReq}>
							{label}
						</Label>
						{labelPosition === 'right' && helperText && typeof helperText === 'string' && (
							<Block type='helper' className='helper-text' pad='8px 0'>
								{helperText}
							</Block>
						)}
						{error && touched && <Block type='err'>{error}</Block>}
					</div>
				</>
			)}
		</Wrap>
	) : (
		<InputWrap suffix={suffix}>{children}</InputWrap>
	)
}

FieldWrap.propTypes = {
	status: PropTypes.string,
	labelPosition: PropTypes.string,
	inputName: PropTypes.string,
	inputId: PropTypes.string,
	isReq: PropTypes.bool,
	multiHoriz: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	touched: PropTypes.bool,
	hasIcon: PropTypes.bool,
	className: PropTypes.string,
	isMulti: PropTypes.bool,
	isActive: PropTypes.bool,
	renderWrap: PropTypes.bool,
	type: PropTypes.string,
}
export default React.memo(FieldWrap)
