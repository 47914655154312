import React, { useEffect } from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { Board, EM, P, Link, SkeletonLoader, Block, UL } from '../../../components/UI'
import { useAsync } from '../../../utils'
import { mcp } from '../../../api'

const MCPSponsorsView = styled.div`
	ul {
		border-bottom: solid 1px;
		padding-bottom: 2em;
		margin-bottom: 2em;
	}
	.add-sponsor-link {
		/* display: none; */
	}
`

const MCPSponsors = () => {
	const { res, isWorking, err, cancel } = useAsync(() => {
		return mcp.sponsors.get()
	})

	useEffect(() => {
		return () => cancel()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Board titleText='Sponsors'>
			<MCPSponsorsView>
				{isWorking ? (
					<UL isPlain>
						{Array(3)
							.fill('')
							.map((_, idx) => (
								<li key={idx}>
									<SkeletonLoader />
								</li>
							))}
					</UL>
				) : err ? (
					<Block type='err'>{err.message}</Block>
				) : isEmpty(res) ? (
					<P>
						<EM>No sponsors found...</EM>
					</P>
				) : (
					<>
						<UL isPlain>
							{res.map(({ id, orgName }) => (
								<li key={id}>
									<Link to={`/mcp/sponsor?id=${id}`}>{orgName}</Link>
								</li>
							))}
						</UL>
					</>
				)}
				<Link className='add-sponsor-link' to='/mcp/sponsor'>
					Create New Sponsor
				</Link>
			</MCPSponsorsView>
		</Board>
	)
}
export default MCPSponsors
