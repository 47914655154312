import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import { Btn } from '../Typography'
import Block from '../Block'
import { ProgressBar } from '../Loaders'
import { HR } from '../Typography'

import UploadBtn from './FileUploader.upload.btn'

import { useState } from '../utils'

const UploadWrap = styled.div`
	& > .flex {
		min-height: 3em;
	}
`

const Uploader = ({ uploadHandler, uploadingHandler, folder }) => {
	const [fileForUpload, setFileForUpload] = useState(null, 'fileForUpload')
	const [error, setError] = useState(null, 'error')

	const isUploading = useRef(false)
	const isCancelled = useRef(false)

	const handleBrowsed = file => {
		setFileForUpload(file)
	}

	useEffect(() => {
		const uploadFile = async () => {
			if (!isCancelled.current) {
				isUploading.current = true
				try {
					uploadingHandler(true)
					if (fileForUpload.size > 3000000) {
						setError('File size cannot exceed 2mb')
						setFileForUpload(null)
						uploadingHandler(false)
					} else {
						const res = await uploadHandler(fileForUpload, folder, {
							onProgress: e => console.log('upload progress: ', e),
						})

						setFileForUpload(null)
						isUploading.current = false
						uploadingHandler(res)
					}
				} catch (err) {
					console.log(err)
					setError(err.msg)
					isUploading.current = false
					setFileForUpload(null)
					uploadingHandler(false)
				}
			}
		}

		if (fileForUpload && !isUploading.current) uploadFile()
	}, [fileForUpload, uploadingHandler, folder, uploadHandler])

	return (
		<UploadWrap className='upload-wrap'>
			<div className='flex'>
				{error ? (
					<Block type='err' usage='overlay' close={() => setError(null)}>
						{error}
					</Block>
				) : (
					!fileForUpload && <UploadBtn browseHandler={handleBrowsed} className='browse-btn' />
				)}
			</div>
			<ProgressBar isDone={!fileForUpload} startBar={!!fileForUpload} />
			{!fileForUpload && <HR className='upload-hr' />}
		</UploadWrap>
	)
}
Uploader.propTypes = {
	uploadHandler: PropTypes.func.isRequired,
	uploadingHandler: PropTypes.func.isRequired,
}
export default React.memo(Uploader)
