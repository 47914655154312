import React from 'react'
import { Redirect } from '@reach/router'
import { useLogout } from '../../../auth'
import { Spinner } from '../../../components/UI'

import { useStoreState } from '../../../store'

const LogoutPage = ({ navigate }) => {
	const logout = useLogout()
	const { isAuthed } = useStoreState(state => state.user)

	if (isAuthed) logout()

	return isAuthed ? <Spinner /> : <Redirect to='/' noThrow />
}
LogoutPage.propTypes = {}
export default LogoutPage
