import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from '../Icons'

const ToastWrap = styled.div`
	align-items: center;
	padding-right: 0.5em;
	.toast-icon {
		align-items: center;
		padding-right: 0.5em;
	}
	.toast-content {
		align-items: center;
	}
	.icon {
		height: 20px;
		${props => props.theme.media.tablet} {
			height: 30px;
		}
	}
`

const Toast = ({ type, children, className }) => {
	return (
		<ToastWrap className={`toast-contents flex${className ? ` ${className}` : ''}`}>
			{type && (
				<div className='toast-icon flex'>
					<Icon type={type} />
				</div>
			)}
			<div className='toast-content flex'>{children}</div>
		</ToastWrap>
	)
}
Toast.propTypes = {
	type: PropTypes.string,
}
export default Toast
