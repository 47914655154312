import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Nav from '../components/Nav'

import { sizes } from '../styles/vars'
import { useStoreActions } from '../store'

const Layout = styled.div`
	padding-top: ${sizes.mobileHeaderHeight}px;
	${props => props.theme.media.tablet} {
		padding-top: ${sizes.tabletHeaderHeight}px;
	}
	${props => props.theme.media.sdesk} {
		padding-top: ${sizes.deskHeaderHeight}px;
	}
`

const Main = styled.main`
	min-height: 90vh;
`

const MainLayout = ({ children, ...props }) => {
	const { toggleNav } = useStoreActions(actions => actions.view)
	return (
		<Layout className='main-layout' {...props}>
			<Nav />
			<Header className='main-header' toggleNav={toggleNav} />
			<Main className='main-main'>{children}</Main>
			<Footer className='main-footer' />
		</Layout>
	)
}
MainLayout.propTypes = {}
export default MainLayout
