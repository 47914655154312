/*
 =================================================
	Wraps formik and forwards functionality to parent
	Also adds a styled form element and optional legend
 =================================================
* */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'

const FormEl = styled.form`
	width: 100%;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'initial')};
	margin: 0 auto;
	padding-top: ${props => (props.hasLegend ? 0 : '32px')};
	text-align: left;
	${props => props.theme.media.tablet} {
		width: auto;
	}
	legend {
		font-size: 3em;
		text-transform: uppercase;
		color: ${props => props.theme.colors.grey.val};
		padding: 16px;
		width: 100%;
		text-align: center;
	}
	.submit-btn {
		margin-top: 1em;
		${props => props.theme.media.tablet} {
			max-width: ${props => props.submitMaxWidth || '250px'};
		}
	}
`
// const isInitialValid = props => {
// 	if (!props.validationSchema) return null
// 	return props.validationSchema.isValidSync(props.initialValues)
// }
const Form = ({ legend, children, maxWidth, submitMaxWidth, className, ...props }) => {
	return (
		<Formik validateOnMount {...props}>
			{formikMethods => {
				const submitHandler = formikMethods.handleSubmit ? formikMethods.handleSubmit : () => {}

				return (
					<FormEl
						onSubmit={submitHandler}
						hasLegend={!!legend}
						maxWidth={maxWidth}
						submitMaxWidth={submitMaxWidth}
						className={className}
					>
						{legend && <legend>{legend}</legend>}
						{children(formikMethods)}
					</FormEl>
				)
			}}
		</Formik>
	)
}
Form.propTypes = {
	legend: PropTypes.string,
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.func,
}
export default Form
