import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import defaultsDeep from 'lodash/defaultsDeep'

import DefaultFields from './DefaultFields'
import defaultVals from './defaultVals'
import { Form, Fields, H5 } from '../UI'
const { SubmitBtn, Text, Checkbox, UploadField } = Fields

const schema = Yup.object({
	name: Yup.string().required('Please enter a name for the page'),
	slug: Yup.string().required('Please enter a unique slug for url').matches(/^\S+$/, {
		message: 'Slug cannot contain spaces (HINT: use a dash instead)',
		excludeEmptyString: true,
	}),
})

const HomePageForm = ({ onSubmit, values }) => {
	const initialVals = defaultsDeep(values, {
		...defaultVals,
		hero: {
			bg: '',
		},
		cta: {
			hide: false,
			txt: '',
			to: '',
		},
	})
	return values ? (
		<Form initialValues={initialVals} onSubmit={onSubmit} validationSchema={schema}>
			{() => (
				<>
					<div className='cols'>
						<div className='col'>
							<DefaultFields />
						</div>
						<div className='col'>
							<H5>Hero</H5>
							<UploadField name='hero.bg' folder='Banners' />
							<H5>Call To Action</H5>
							<Checkbox name='cta.hide' label='Hide CTA' />
							<Text name='cta.txt' label='CTA Text' />
							<Text name='cta.to' label='CTA Link' />
						</div>
					</div>
					<SubmitBtn>Save</SubmitBtn>
				</>
			)}
		</Form>
	) : null
}
HomePageForm.propTypes = {
	values: PropTypes.shape({}),
	onSubmit: PropTypes.func.isRequired,
}
export default HomePageForm
