import React from 'react'
import isFunction from 'lodash/isFunction'

const useState = (initialState, nameSpace = 'swfState') => {
	const s = {}
	s[nameSpace] = initialState
	const [state, setState] = React.useState(s)

	return [
		state[nameSpace],
		incomingState => {
			const newState = isFunction(incomingState) ? incomingState(state[nameSpace]) : incomingState
			const st = {}
			st[nameSpace] = newState
			setState(st)
		},
	]
}

export default useState
