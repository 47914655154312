import styled from 'styled-components'

export default styled.div`
	padding-bottom: 5vh;
	h5 {
		margin: 1em 0;
	}
	.flex-tablet {
		${props => props.theme.media.tablet} {
			display: flex;
			flex-direction: column-reverse;
		}
	}
	.desk-wrap {
		.col {
			${props => props.theme.media.sdesk} {
				flex-grow: 1;
				max-width: 50%;
				padding: 0 5vw;
			}
		}
	}
	.submit-btn {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		${props => props.theme.media.tablet} {
			margin: 0;
			margin-left: auto;
			max-width: 250px;
		}
	}
	.publish-btns {
		button {
			display: block;
			padding: 0.5em 1em;
		}
	}
`
