import React from 'react'
import styled from 'styled-components'

const BorderView = styled.div`
	padding: 1em;
	display: block;
	border: solid 2px ${props => props.theme.colors.grey.light().val};
	border-radius: 5px;
`

const Border = ({ children }) => {
	return <BorderView>{children}</BorderView>
}
export default Border
