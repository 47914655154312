import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isString from 'lodash/isString'
import { useField } from 'formik'

import { iconLib } from '../../../Icons/fa.defaults'
import Label from '../Label'
import Icon from '../../../Icons'
import { Btn, H6 } from '../../../Typography'
import { useState } from '../../../utils'
import Modal from '../../../Modal'
import Block from '../../../Block'

const Wrap = styled.div`
	.select-field {
		padding: 1em;
		align-items: flex-start;
	}
	.select-icon-btn {
		.icon {
			height: 30px;
		}
	}
	.remove-icon-btn {
		color: ${props => props.theme.colors.err.val};
	}
`
const ModalWrap = styled.div`
	${H6} {
		color: ${props => props.theme.colors.grey.val};
		font-weight: 400;
	}
	.icon-group {
		margin: 1em 0;
		.icons {
			margin-top: 0.5em;
			.icon {
				height: 30px;
				color: ${props => props.theme.colors.primary.light().val};
				&.selected,
				&:hover {
					color: ${props => props.theme.colors.secondary.val};
				}
			}
		}
	}
`

const FAIconSelectField = ({
	label,
	selectTxt = 'Select Icon...',
	isReq,
	allow,
	showGroups = true,
	...props
}) => {
	const [field, { error, touched }, helpers] = useField(props)
	const [showMenu, setShowMenu] = useState(false, 'showMenu')
	const [selectedIcon, setSelectedIcon] = useState(get(field, 'value', null), '')
	const filterByCats = isString(allow) ? [allow] : allow
	const filteredIcons = allow
		? iconLib.filter(({ group }) => includes(filterByCats, group))
		: iconLib

	const setIcon = res => {
		setSelectedIcon(res)
		if (helpers.setValue) helpers.setValue(res)
		else if (props.onChange) props.onChange(res)
		setShowMenu(false)
	}

	const removeIcon = () => {
		setSelectedIcon(null)
		if (helpers.setValue) helpers.setValue('')
		else if (props.onChange) props.onChange(null)
	}

	return (
		<Wrap {...props}>
			{label && <Label isReq={isReq}>{label}</Label>}
			<div className='select-field flex'>
				<Btn onClick={() => setShowMenu(true)} className='select-icon-btn' title='Select icon'>
					{selectedIcon ? <Icon type={selectedIcon} /> : selectTxt}
				</Btn>
				{selectedIcon && (
					<Btn onClick={removeIcon} className='remove-icon-btn' title='Remove icon'>
						<Icon type='x' />
					</Btn>
				)}
			</div>
			{touched && error ? <Block type='err'>{error}</Block> : null}
			<Modal isOpen={showMenu} closeHandler={() => setShowMenu(false)} titleText={selectTxt}>
				<ModalWrap>
					{filteredIcons.map(({ group, name, icons }) => (
						<div className={showGroups ? 'icon-group' : ''} key={group}>
							{showGroups && <H6>{name}</H6>}
							<div className='icons'>
								{Object.keys(icons).map(icon => (
									<Btn key={icon} onClick={() => setIcon(icon)}>
										<Icon type={icon} className={icon === selectedIcon ? 'selected' : ''} />
									</Btn>
								))}
							</div>
						</div>
					))}
				</ModalWrap>
			</Modal>
		</Wrap>
	)
}
FAIconSelectField.propTypes = {
	label: PropTypes.string,
	isReq: PropTypes.bool,
}
export default FAIconSelectField
