import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Branding from './Header.branding'
import NavBtn from './Header.nav-ico'
import { Link } from '../UI'

import { sizes } from '../../styles/vars'
import { useStoreState } from 'easy-peasy'

const Header = styled.header`
	height: ${sizes.mobileHeaderHeight}px;
	background: ${props => props.theme.colors.grey.val};
	color: ${props => props.theme.colors.white.val};
	display: flex;
	align-items: center;
	padding: 0 1em;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	${props => props.theme.media.tablet} {
		height: ${sizes.tabletHeaderHeight}px;
	}
	${props => props.theme.media.sdesk} {
		height: ${sizes.deskHeaderHeight}px;
	}
	.mcp {
		font-size: 2em;
		padding-left: 0.3em;
		em {
			font-size: 0.9rem;
			color: ${props => props.theme.colors.white.dark().val};
		}
	}
	.main-nav-action {
		margin-left: auto;
	}
`

const MainHeader = ({ toggleNav, ...props }) => {
	const { isAuthed } = useStoreState(state => state.user)

	return (
		<Header {...props}>
			<Branding />
			{isAuthed ? (
				<>
					<div className='mcp'>
						MCP<em>(Manager Control Panel)</em>
					</div>
					<NavBtn className='main-nav-action' onClick={() => toggleNav()} />
				</>
			) : (
				<Link className='main-nav-action' to='/login'>
					Login
				</Link>
			)}
		</Header>
	)
}
MainHeader.propTypes = {
	toggleNav: PropTypes.func.isRequired,
}
export default MainHeader
