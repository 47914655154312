import Auth from '@aws-amplify/auth'
import toLower from 'lodash/toLower'
import get from 'lodash/get'
import { useStoreActions, useStoreState } from '../store'
import { me } from '../api'

/**
 *  Authenticates current user and stores the cognito user in the global cache
 * */
export const useAuth = () => {
	const { isAuthed } = useStoreState(state => state.user)
	const { setCognitoUser, setUser, setIsAuthed } = useStoreActions(actions => actions.user)
	return async () => {
		try {
			// we don't autheticate if we already are
			if (isAuthed) {
				console.log('Auth.useAuth - already authenticated!')
				return
			}
			// console.log('Auth.useAuth - authenticating with cognito...')
			const cognitoUser = await Auth.currentAuthenticatedUser()

			setCognitoUser(cognitoUser)

			if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
				return {
					redirect: '/create-pass',
				}
			}

			// console.log('Auth.useAuth - fetching user from db...')
			const dbUser = await me.get()
			// console.log('me: ', dbUser)
			setUser(dbUser)

			// console.log('Auth.useAuth - setting app to authenticated')
			setIsAuthed()
		} catch (err) {
			return Promise.reject(err)
		}
	}
}

const useClearAuth = () => {
	const { clearUser } = useStoreActions(actions => actions.user)
	return () => {
		clearUser()
	}
}

export const useLogin = () => {
	const { setCognitoUser, setIsAuthed, setUser } = useStoreActions(actions => actions.user)
	return async ({ email, password }) => {
		try {
			const cognitoUser = await Auth.signIn(toLower(email), password)
			console.log('Auth.login cognito user: ', cognitoUser)

			if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
				setCognitoUser(cognitoUser)
				return {
					redirect: '/create-pass',
				}
			}

			if (cognitoUser.challengeName) {
				console.warn('Auth.login - unknown user challenge: ', cognitoUser.challengeName)
				// handleErr(user.challengeName)
				setCognitoUser(cognitoUser)
				return cognitoUser.challengeName
			}

			setCognitoUser(cognitoUser)

			const dbUser = await me.get()
			console.log('useLogin me: ', dbUser)
			setUser(dbUser)

			setIsAuthed()

			if (get(dbUser, 'preferences.homePath'))
				return {
					redirect: dbUser.preferences.homePath,
				}
		} catch (err) {
			console.error(err)
			return Promise.reject(err)
		}
	}
}

export const useLogout = () => {
	const clearAuth = useClearAuth()
	return async () => {
		try {
			await Auth.signOut()

			console.log('Signed out from cognito')

			clearAuth()
		} catch (err) {
			console.error(err)
			return Promise.reject(err)
		}
	}
}

export const useCreatePass = () => {
	// const {setCognitoUser, setIsAuthed, setUser} = useStoreActions(actions => actions.user.setCognitoUser)
	const authUser = useAuth()
	const storedCognitoUser = useStoreState(state => state.user.cognito)
	return async pw => {
		try {
			await Auth.completeNewPassword(storedCognitoUser, pw)

			await authUser()

			return {
				ok: true,
			}
		} catch (err) {
			console.error(err)
			return Promise.reject(err)
		}
	}
}

export const useResetPass = () => {
	return async (email, code, pw) => {
		try {
			const res = await Auth.forgotPasswordSubmit(email, code, pw)

			return res
		} catch (err) {
			console.error(err)
			return Promise.reject(err)
		}
	}
}
