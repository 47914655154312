import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H2 } from '../Typography'

const BoardWrap = styled.div`
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	${props => props.theme.media.tablet} {
		max-width: ${props => (props.isFull ? '100%' : '700px')};
	}
	${props => props.theme.media.sdesk} {
		max-width: ${props => (props.isFull ? '1400px' : '700px')};
	}
	& > header {
		padding: 1em;
		padding-bottom: 0.5em;
		border-bottom: 1px solid ${props => props.theme.colors.grey.light().val};
		.sub-title {
			margin: 1em 0;
		}
		${props => props.theme.media.sdesk} {
			padding: 2em;
			padding-bottom: 1.5em;
		}
	}
	.board-content {
		width: 100%;
		min-height: 50vh;
		justify-content: center;
		padding: 1em;
		padding-top: 0.5em;
		${props => props.theme.media.tablet} {
		}
		${props => props.theme.media.sdesk} {
			padding: 2em;
			padding-top: 1.5em;
		}
	}
	${props => (props.theme.css.board ? props.theme.css.board : '')}
`

const Board = ({ titleText, subTitleText, children, ...props }) => {
	return (
		<BoardWrap className='board' {...props}>
			{titleText && (
				<header>
					<H2 className='board-title'>{titleText}</H2>
					{subTitleText && <div className='sub-title'>{subTitleText}</div>}
				</header>
			)}
			<div className='board-content'>{children}</div>
		</BoardWrap>
	)
}
Board.propTypes = {
	titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
export default Board
