import React from 'react'
import { Redirect } from '@reach/router'
import * as Yup from 'yup'
import get from 'lodash/get'

import { Form, Fields, Board } from '../../../components/UI'

import { useResetPass } from '../../../auth'

import { useStoreState } from '../../../store'
import { parseQueryParams, useState } from '../../../utils'

const { SubmitBtn, Pass, Email, Text } = Fields

const schema = Yup.object({
	password: Yup.string().required('Enter a new password'),
	code: Yup.string().required('Enter the rest code from email'),
	email: Yup.string().required('Enter the email address for your account'),
})

const ResetPass = ({ location }) => {
	const { u: email, c: code } = parseQueryParams(location.search)
	const { isAuthed, dashPath } = useStoreState(state => state.user)
	const [redirect, setRedirect] = useState(null, 'redirect')

	const createNewPW = useResetPass()

	const submitHandler = async vals => {
		try {
			await createNewPW(get(vals, 'email', email), get(vals, 'code', code), vals.password)
			setRedirect('/login')
		} catch (err) {
			return Promise.reject(err)
		}
	}
	return isAuthed || redirect ? (
		<Redirect to={redirect || dashPath || '/mcp/dash'} noThrow />
	) : (
		<Board titleText='Reset password'>
			<Form
				initialValues={{
					email: email,
					code: code,
					password: '',
				}}
				onSubmit={async (vals, { setSubmitting }) => {
					try {
						await submitHandler(vals)
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{({ isValid, isSubmitting }) => (
					<>
						{!email && <Email name='email' placeholder='Enter your email' isReq />}
						{!code && <Text name='code' placeholder='Enter your reset code' isReq />}
						<Pass name='password' placeholder='Create new password' isReq />
						<SubmitBtn isValid={isValid} isSubmitting={isSubmitting} isInverted>
							Create Password
						</SubmitBtn>
					</>
				)}
			</Form>
		</Board>
	)
}
ResetPass.propTypes = {}
export default ResetPass
