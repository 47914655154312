import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../../Icons'

const SelectWrap = styled.div`
	position: relative;
	.icon {
		position: absolute;
		top: 50%;
		right: 0.3em;
		height: 1em;
		width: auto;
		transform: translate3d(0, -50%, 0);
		color: ${props => props.theme.colors.grey.val};
	}
`
const Sel = styled.select``
const Option = styled.option``

const Select = ({ options, ...props }) => {
	return (
		<SelectWrap>
			<Sel {...props}>
				{options.map(option => (
					<Option key={option.val || option} value={option.val || option}>
						{option.txt ? option.txt : option.val || option}
					</Option>
				))}
			</Sel>
			<Icon type='caretDown' />
		</SelectWrap>
	)
}
Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.shape({
				val: PropTypes.string.isRequired,
				txt: PropTypes.string,
			}),
			PropTypes.string,
		])
	).isRequired,
}
export default Select
