import React from 'react'
import map from 'lodash/map'
import { Fields } from '../../../../components/UI'
import config from '../../../../styles/theme.config'

const { Text, ArrayFields, Checkbox, RT } = Fields

const MCPEventSectionLinks = () => {
	return (
		<>
			<RT name='content' label='Content (Above Schedule)' colors={map(config.colors, val => val)} />
			<ArrayFields name='agenda' label='Agenda' addText='Add agenda group'>
				<Checkbox name='hide' label='Hide Group?' default={false} />
				<Text name='name' label='Title for group (e.g., "Sat, 04/05/20")' isPreview />
				<ArrayFields name='items' label='Agenda Items'>
					<Checkbox name='hide' label='Hide agenda item?' default={false} />
					<Text name='time' label='Time' isPreview isReq />
					<Text name='title' label='Agenda item description' isReq />
					<Text
						name='link'
						label='Link (optional)'
						helperText='External links must start with "http", internal must start with "/"'
					/>
				</ArrayFields>
			</ArrayFields>
		</>
	)
}
MCPEventSectionLinks.propTypes = {}
export default MCPEventSectionLinks
