import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import Form from '../../Form'
import SubmitBtn from '../SubmitButton'

const FormWrap = styled.div`
	legend {
		font-size: 2em;
		text-align: left;
	}
`

const ArrayFieldsForm = ({
	submitHandler,
	titleText,
	saveNewText = 'Add',
	saveExistingText = 'Save',
	FormFields,
	schema,
	values,
}) => {
	const initialValues = isEmpty(values)
		? FormFields.reduce((obj, { props }) => {
				obj[props.name] = typeof props.default === 'undefined' ? '' : props.default
				return obj
		  }, {})
		: values

	console.log('initial values: ', initialValues)
	return (
		<FormWrap>
			<Form
				initialValues={initialValues}
				onSubmit={submitHandler}
				validationSchema={schema}
				className='scrollable'
			>
				{() => (
					<>
						<header>
							{titleText ? <legend>{titleText}</legend> : null}
							<SubmitBtn>{isEmpty(values) ? saveNewText : saveExistingText}</SubmitBtn>
						</header>
						<div className='scroll'>
							<div className='scroll-child'>{FormFields}</div>
						</div>
					</>
				)}
			</Form>
		</FormWrap>
	)
}
ArrayFieldsForm.propTypes = {
	submitHandler: PropTypes.func.isRequired,
	values: PropTypes.shape({}),
	titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	FormFields: PropTypes.arrayOf(PropTypes.node).isRequired,
	saveNewText: PropTypes.string,
	saveExistingText: PropTypes.string,
}
export default ArrayFieldsForm
