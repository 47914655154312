import React from 'react'
import { Fields } from '../../../../components/UI'
import { mcp } from '../../../../api'

const { UploadField, ArrayFields, Checkbox } = Fields

const MCPEventSectionPics = ({ folder }) => {
	return (
		<ArrayFields name='pics' label='Photo Gallery'>
			<Checkbox name='hide' label='Hide?' default={false} />
			<UploadField
				name='pic'
				label='Upload/Select Pic'
				isPreview
				previewType='pic'
				folder={folder}
				allowedFolders={[folder]}
				api={mcp.files}
			/>
		</ArrayFields>
	)
}
MCPEventSectionPics.propTypes = {}
export default MCPEventSectionPics
