import { api } from './api'

export const gen = (data, options = {}) =>
	api('post', '/contact', {
		body: data,
		noCreds: true,
		...options,
	})

export const vendor = (data, options = {}) =>
	api('post', '/contact/vendor', {
		body: data,
		noCreds: true,
		...options,
	})

export const volunteer = (data, options = {}) =>
	api('post', '/contact/volunteer', {
		body: data,
		noCreds: true,
		...options,
	})

export const volunteerSkills = options =>
	api('get', '/schemas/volunteer/skills', {
		noCreds: true,
		...options,
	})
