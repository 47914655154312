import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import { useState, parseQueryParams, useToaster } from '../../../utils'
import { Board, SkeletonLoader, Spinner, Block } from '../../../components/UI'
import { mcp } from '../../../api'

import { LandingPageForm } from '../../../components/Pages'

const PageForm = type => (type === 'landing' ? LandingPageForm : LandingPageForm)

// const Form = ({ pageID, slug, ...props }) =>
// 	pageID === '5fa9ddce02684e838303b673' || slug === 'home' ? (
// 		<Home {...props} />
// 	) : (
// 		<Gen {...props} />
// 	)

const MCPEditPage = ({ location }) => {
	const { id, slug } = parseQueryParams(location)
	const toast = useToaster()

	const [isLoading, setLoading] = useState(id || slug, 'isLoading')

	const [pageData, setpageData] = useState({}, 'pageData')

	const [error, setError] = useState(null, 'error')

	const pageName = pageData.name

	const Form = PageForm(pageData.type)

	useEffect(() => {
		const fetchPage = async () => {
			setLoading(true)
			try {
				const page = await mcp.pages.get({ pageID: id, slug })
				if (page) setpageData(page)
				setLoading(false)
			} catch (err) {
				console.error(err)
				setError(err.message)
				setLoading(false)
			}
		}
		if (id || slug) fetchPage()
	}, [id, slug])

	const handleSubmit = async (vals, opts) => {
		try {
			let result
			if (vals.id) result = await mcp.pages.update(vals)
			else result = await mcp.pages.create(vals)

			if (!id) navigate(`/mcp/page?id=${result.id}`)
			else toast('Page saved!', { type: 'ok' })
		} catch (err) {
			setError(err.message)
		}
	}

	return (
		<Board titleText={isLoading ? <Spinner /> : pageName || 'New Page'}>
			{isLoading ? (
				<>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</>
			) : error ? (
				<Block type='err'>{error}</Block>
			) : (
				<Form data={pageData} handler={handleSubmit} />
			)}
		</Board>
	)
}
MCPEditPage.propTypes = {
	id: PropTypes.string,
}
export default MCPEditPage
