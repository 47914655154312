/*
 =================================================
  Global store model
  This gets injected into a react context provider from easy-peasy - see gatsby-browser.js
 =================================================
* */
import { action } from 'easy-peasy'
import get from 'lodash/get'

const user = {
	// current user state
	cognito: null,
	isAuthed: false,
	groups: [],
	me: null,
	dashPath: null,
	isAdmin: false,
	org: null,

	//set current user state
	setCognitoUser: action((state, user) => {
		if (!get(user, 'username')) return
		state.cognito = user
	}),

	setUser: action((state, user) => {
		if (!get(user, 'email')) return
		state.me = user
		state.groups = get(user, 'groups', [])
		state.dashPath = get(user, 'preferences.homePath', '/mcp/dash')
		state.isAdmin = get(user, 'isAdmin', false)
	}),

	setOrg: action((state, org) => {
		if (!get(org, 'id')) return

		state.org = org
	}),

	setDashPath: action((state, path) => {
		if (!path) return
		state.dashPath = path
	}),

	setIsAuthed: action(state => {
		state.isAuthed = true
	}),

	clearUser: action(state => {
		state.isAuthed = false
		state.cognito = null
		state.permissions = {}
		state.dashPath = null
		state.me = null
		state.org = null
	}),
}

const view = {
	context: 'public',

	isNavOpen: false,

	setContext: action((state, context) => {
		if (!context && state.context === 'public') return
		if (state.context !== context) state.context = context || 'public'
	}),

	toggleNav: action(state => {
		state.isNavOpen = !state.isNavOpen
	}),
}

export default {
	user,
	view,
}
