import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import isString from 'lodash/isString'
import { Draggable } from 'react-beautiful-dnd'

import { Btn, P } from '../../../../Typography'
import Icon from '../../../../Icons'
import Modal from '../../../../Modal'
import Block from '../../../../Block'
import { useState } from '../../../../utils'

import PreviewItem from './ArrayFields.item.preview'

const ArrayItemWrap = styled.div`
	width: 100%;
	padding: 1em;
	border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};
	color: ${props => props.theme.colors.secondary.val};
	align-items: stretch;
	background: ${props => props.theme.colors.white.val};
	&:hover {
		border-color: ${props => props.theme.colors.secondary.val};
		cursor: pointer;
	}
	&:nth-last-child(2) {
	}
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 3px;
		/* border-radius: 0 3px 3px 0; */
		background: ${props => props.theme.colors.secondary.val};
		display: ${props => (props.isSelected ? 'block' : 'none')};
	}
	&.hidden {
		color: ${props => props.theme.colors.disabled.val};
		&:hover {
			color: ${props => props.theme.colors.secondary.val};
		}
	}
	.item-previews {
		flex-grow: 1;
	}
	.item {
	}
	.hidden-icon {
		margin-left: auto;
	}
	.remove-item-btn {
		color: ${props => props.theme.colors.err.val};
		margin-left: auto;
	}
`

const ArrayItem = ({
	previewFields,
	remove,
	className,
	isSelected,
	selectHandler,
	vals,
	id,
	idx,
}) => {
	const [alertOpen, setAlertOpen] = useState(false, 'alertOpen')

	const isHidden = get(vals, 'hide') === true

	const previews = previewFields
		? previewFields.map(preview => (isString(preview) ? { name: preview } : preview))
		: [
				{
					name: findKey(vals, isString),
				},
		  ]

	return (
		<Draggable draggableId={id} index={idx}>
			{({ draggableProps, dragHandleProps, innerRef }) => (
				<ArrayItemWrap
					className={`flex preview-item${className ? ` ${className}` : isHidden ? ' hidden' : ''}`}
					isSelected={isSelected}
					itemQty={previewFields ? previewFields.length : 1}
					{...draggableProps}
					{...dragHandleProps}
					ref={innerRef}
				>
					<div
						className='flex item-previews'
						onClick={selectHandler}
						role='button'
						title='Edit item'
					>
						{previews.map(({ name, previewType = 'string', ...props }) => (
							<PreviewItem
								key={name}
								className={`item ${previewType}`}
								type={previewType}
								{...props}
							>
								{vals[name]}
							</PreviewItem>
						))}
					</div>
					{isHidden && <Icon type='eyeClosed' className='hidden-icon' />}
					<Btn
						className='remove-item-btn'
						onClick={e => {
							e.preventDefault()
							setAlertOpen(true)
						}}
						title='Remove Item'
					>
						<Icon type='x' />
					</Btn>
					<Modal
						type='alert'
						isOpen={alertOpen}
						closeHandler={() => setAlertOpen(false)}
						confirm={remove}
					>
						<Block type='warn'>
							<P>Are you sure you want to delete this?</P>
						</Block>
					</Modal>
				</ArrayItemWrap>
			)}
		</Draggable>
	)
}
ArrayItem.propTypes = {
	previewFields: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				previewType: PropTypes.string,
				isTrunc: PropTypes.bool,
			}),
		])
	),
	vals: PropTypes.shape({}).isRequired,
	isSelected: PropTypes.bool,
	selectHandler: PropTypes.func.isRequired,
	className: PropTypes.string,
	remove: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	idx: PropTypes.number.isRequired,
}
export default ArrayItem
