import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from '../UI'

const Nav = styled.nav``

const MCPNav = props => {
	return (
		<Nav {...props}>
			<li>
				<Link to='/mcp/pages'>Pages</Link>
			</li>
			<li>
				<Link to='/mcp/events'>Events</Link>
			</li>
			<li>
				<Link to='/mcp/media-library'>Media Library</Link>
			</li>
		</Nav>
	)
}
MCPNav.propTypes = {}
export default MCPNav
