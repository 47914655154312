import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
	display: ${props => (props.pos === 'left' || props.pos === 'right' ? 'inline-block' : 'block')};
	color: ${props => props.theme.colors.grey.val};
	position: relative;
	padding-left: ${props => (props.isReq ? '0.5em' : 0)};
	.asterix {
		position: absolute;
		left: 0;
	}
`

const InputLabel = ({ children, isReq, pos, className, htmlFor }) => (
	<Label pos={pos} className={className} htmlFor={htmlFor} isReq={isReq}>
		{isReq && <span className='asterix'>*</span>}
		{children}
	</Label>
)
export default InputLabel
