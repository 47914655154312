import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import { api } from './api'

const env = process.env.REACT_APP_ENV

export const users = {
	get: (uid, options = {}) =>
		api('get', `/users${isString(uid) ? `?uid=${uid}` : ''}`, isPlainObject(uid) ? uid : options),

	create: (body, options = {}) =>
		api('post', '/users', {
			body,
			...options,
		}),

	update: (body, options = {}) =>
		api('put', '/users', {
			body,
			...options,
		}),
}

export const events = {
	get: ({ eventID, orgID, eventCode, ...options } = {}) =>
		api('get', `/mcp/events`, {
			params: {
				id: eventID,
				orgid: orgID,
				code: eventCode,
			},
			...options,
		}),

	create: (orgID, body, options = {}) =>
		api('post', `/mcp/events/${orgID}`, {
			body,
			...options,
		}),

	update: (body, options = {}) =>
		api('put', '/mcp/events', {
			body,
			...options,
		}),

	publish: (server, options = {}) =>
		api('post', `/mcp/site/build`, {
			params: {
				env: env === 'dev' ? 'dev' : server,
			},
			...options,
		}),
}

export const pages = {
	get: ({ pageID, slug, ...options } = {}) =>
		api('get', `/mcp/pages`, {
			params: {
				id: pageID,
				slug,
			},
			...options,
		}),

	create: (body, options = {}) =>
		api('post', `/mcp/pages`, {
			body,
			...options,
		}),

	update: (body, options = {}) =>
		api('put', '/mcp/pages', {
			body,
			...options,
		}),

	publish: (server, options = {}) =>
		api('post', `/mcp/site/build`, {
			params: {
				env: env === 'dev' ? 'dev' : server,
			},
			...options,
		}),
}

export const org = {
	get: (id, options = {}) => api('get', `/mcp/orgs?id=5ecafd5a1480065f3fbab3ac`, options),

	create: (body, options = {}) =>
		api('post', '/mcp/orgs', {
			body,
			...options,
		}),
}

export const files = {
	get: (folder, { params = {}, ...options } = {}) =>
		api('get', '/mcp/files', {
			params: {
				dir: folder,
				...params,
			},
			...options,
		}),

	getFolders: (options = {}) => api('get', '/mcp/folders', options),

	upload: (file, dir, { params = {}, ...options } = {}) =>
		api('post', '/mcp/files', {
			file,
			params: {
				dir,
				...params,
			},
			...options,
		}),

	update: (body, dir, { params = {}, ...options } = {}) =>
		api('put', '/mcp/files', {
			body,
			params: {
				dir,
				...params,
			},
			...options,
		}),

	delete: (fileID, options = {}) => api('delete', `/mcp/files/${fileID}`, options),
}

export const sponsors = {
	get: ({ id, ...options } = {}) =>
		api('get', `/mcp/sponsors`, {
			params: {
				id,
			},
			...options,
		}),

	create: (body, options = {}) =>
		api('post', `/mcp/sponsors`, {
			body,
			...options,
		}),

	update: (body, options = {}) =>
		api('put', '/mcp/sponsors', {
			body,
			...options,
		}),
}
