import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { H5, Fields } from '../../UI'
import LandingSectionForm from './Landing.section'

const { ArrayFields } = Fields

const LandingSectionsFormView = styled.div``

const heroSchema = {
	featuredItems: Yup.array().min(1, 'Must have at least one featured item'),
}

const LandingSectionsForm = () => {
	return (
		<LandingSectionsFormView>
			<H5>Sections</H5>
			<ArrayFields
				name='sections'
				addText='Add Section'
				form={{
					selectDefaultTxt: 'Select type...',
					selectLabel: 'Content Type',
				}}
				forms={[
					{
						id: 'hero',
						txt: 'Page Hero',
						description:
							'Above the fold section - There should never be more than one hero section per page.',
						form: p => (
							<LandingSectionForm
								formID='hero'
								defaultVals={{
									name: 'Hero',
									version: 'one',
									icon: 'hero',
									featuredItems: [],
								}}
								schema={heroSchema}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'gen',
						txt: 'General Content',
						form: p => (
							<LandingSectionForm
								formID='gen'
								defaultVals={{ icon: 'info', name: '', content: '', version: 'rt' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'events',
						txt: 'Events',
						form: p => (
							<LandingSectionForm
								formID='events'
								defaultVals={{ icon: 'events', name: 'Events', events: [], version: 'list' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'form',
						txt: 'Form',
						form: p => (
							<LandingSectionForm
								formID='form'
								defaultVals={{ icon: 'form', name: '', version: '', content: '' }}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'personnel',
						txt: 'Personnel List',
						form: p => (
							<LandingSectionForm
								formID='personnel'
								defaultVals={{
									icon: 'personnel',
									name: '',
									personnel: [],
									content: '',
									version: 'one',
								}}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'disclaimer',
						txt: 'Disclaimer Box',
						form: p => (
							<LandingSectionForm
								formID='disclaimer'
								defaultVals={{
									icon: 'warn',
									name: '',
									version: 'warn',
									disclaimer: {
										hide: false,
										icon: 'medMask',
										headline: '',
										copy: '',
									},
								}}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
					{
						id: 'sponsor',
						txt: 'Sponsors',
						form: p => (
							<LandingSectionForm
								formID='sponsor'
								defaultVals={{
									icon: 'tulip',
									name: '',
									content: '',
									sponsors: [],
									version: 'one',
								}}
								{...p}
							/>
						),
						previewFields: [{ name: 'icon', type: 'icon' }, 'name'],
					},
				]}
			/>
		</LandingSectionsFormView>
	)
}
LandingSectionsForm.propTypes = {}
export default LandingSectionsForm
